
function BballEvalLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px" viewBox="100 100 500 580">
		<g><path d="M 354.5,108.5 C 361.331,107.051 366.831,109.051 371,114.5C 394.001,160.167 416.168,206.167 437.5,252.5C 486.152,259.712 534.818,266.879 583.5,274C 599.391,277.349 602.891,285.849 594,299.5C 558.388,334.289 523.055,369.289 488,404.5C 490.284,423.21 493.118,441.877 496.5,460.5C 495.178,460.33 494.011,460.663 493,461.5C 484.54,451.938 473.874,446.938 461,446.5C 456.393,447.055 451.893,448.055 447.5,449.5C 435.169,440.001 421.502,437.334 406.5,441.5C 405.667,420.508 405.167,399.508 405,378.5C 396.653,352.077 379.153,342.577 352.5,350C 341.219,356.604 333.719,366.104 330,378.5C 329.667,417.833 329.333,457.167 329,496.5C 312.838,484.334 294.671,478.834 274.5,480C 259.884,483.281 250.384,492.114 246,506.5C 242.877,514.553 242.877,522.553 246,530.5C 253.003,537.167 260.836,542.667 269.5,547C 254.239,555.797 238.573,563.797 222.5,571C 215.966,572.493 211.133,570.327 208,564.5C 206.589,559.556 206.089,554.723 206.5,550C 215.479,501.623 223.646,453.123 231,404.5C 195.135,369.637 159.469,334.303 124,298.5C 116.385,284.899 120.218,276.732 135.5,274C 180.833,267.333 226.167,260.667 271.5,254C 274.702,253.802 277.702,252.969 280.5,251.5C 302.288,207.592 323.788,163.592 345,119.5C 347.314,115.025 350.48,111.358 354.5,108.5 Z"/></g>
		<g><path d="M 364.5,366.5 C 374.518,365.92 381.351,370.254 385,379.5C 385.333,405.167 385.667,430.833 386,456.5C 387.372,462.038 390.872,465.372 396.5,466.5C 401.46,464.356 406.46,462.19 411.5,460C 422.889,457.532 432.556,460.532 440.5,469C 442.355,469.422 444.189,469.922 446,470.5C 460.976,463.629 473.81,466.462 484.5,479C 490.277,480.804 496.11,480.97 502,479.5C 521.498,480.416 530.332,490.749 528.5,510.5C 528.369,516.2 527.869,521.866 527,527.5C 518.867,554.9 510.533,582.233 502,609.5C 500.354,615.615 500.187,621.782 501.5,628C 500.081,639.791 499.414,651.625 499.5,663.5C 458.833,663.5 418.167,663.5 377.5,663.5C 377.667,649.163 377.5,634.829 377,620.5C 375.234,611.304 371.567,602.971 366,595.5C 338.555,564.714 306.055,540.88 268.5,524C 265.709,522.881 264.043,520.881 263.5,518C 265.506,501.816 274.506,495.816 290.5,500C 293.893,500.798 297.226,501.798 300.5,503C 309.833,508 319.167,513 328.5,518C 336.03,522.133 342.53,520.967 348,514.5C 349.313,469.546 349.98,424.546 350,379.5C 352.584,372.487 357.417,368.154 364.5,366.5 Z"/></g>
	</svg>
  );
}

export default BballEvalLogo;



