import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useLeaguesStore } from '../../store/fireStore';
import { LeagueDef, UserRole } from '../../types/firestoreTypes';
import { useGenericForm } from './GenericForm';
import { InputFieldProps, InputFieldType, Validation } from './InputForm';


export const useLeagueForm = () => {
  const user = useAuthStore((state) => state.user);
  const createLeague = useLeaguesStore((state) => state.createDocument);
  const updateLeague = useLeaguesStore((state) => state.updateDocument);
  const deleteLeague = useLeaguesStore((state) => state.deleteDocument);

  const [initial, setInitial] = useState<Partial<LeagueDef>>();

  useEffect(() => {
    if (!user) return;
    setInitial({
      roles: {
        [user.uid]: UserRole.Owner,
      },
    });
  }, [user]);

  const createFields = (league?: LeagueDef) => {
    const fields: InputFieldProps[] = [
      {
        label: 'Name',
        propName: 'name',
        type: InputFieldType.Text,
        validate: Validation.required,
      },
      {
        label: 'Logo',
        propName: 'imagePath',
        type: InputFieldType.Image,
        validate: Validation.file,
      },
    ];
    return fields;
  };

  const { openEditForm, openNewForm } = useGenericForm<LeagueDef>({
    formLabel: "League",
    createFields,
    initial,
    typeNameToDelete: true,
    createDocument: createLeague,
    updateDocument: updateLeague,
    deleteDocument: deleteLeague,
  });
  return { openNewLeagueForm: openNewForm, openEditLeagueForm: openEditForm };
};
