import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import CenteredLoading from './basic/CenteredLoading';

function NotFound({ children } : PropsWithChildren) {
  return (
    <CenteredLoading>
      {children ?? "Page Not Found"}<br/>
      <br/>
      <Link to="/">Go Home</Link>
    </CenteredLoading>
  );
}

export default NotFound;
