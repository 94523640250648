import { useNavigate, useParams } from 'react-router-dom';
import CenteredLoading from '../basic/CenteredLoading';
import { useAuthStore } from '../../store/authStore';
import { useAreasStore, useEventsStore, useLeaguesStore, useRecordsStore, useUsersStore } from "../../store/fireStore";
import { useEffect } from "react";
import { createWhereClause } from "../../firestore/firebase";
import ActionButtonBar from "../basic/ActionButtonBar";
import ActionButton, { AddButton, RemoveButton } from "../basic/ActionButton";
import { useUIStore } from "../../store/uiStore";
import { ScrollingView } from "../basic/View";
import AreasTable from "../tables/AreasTable";
import Breadcrumb from '../tables/Breadcrumb';
import { createRecordsCsv } from '../../types/csvHelper';
import { useAreaForm } from '../forms/AreaForm';
import { useEventForm } from '../forms/EventForm';
import PropertiesTable from '../basic/PropertiesTable';
import usePermissions from './PermissionsHook';
import Header from '../basic/Header';
import { rangeToString, timeStampToString } from '../../types/helpers';
import NotFound from '../NotFound';
import EvaluatorEventView from '../eval/EvaluatorEventView';
import { useGetRequisites } from '../../firestore/firestoreHooks';

function EventView() {
  const navigate = useNavigate();
	const { eventId } = useParams<{ eventId: string }>();
  const user = useAuthStore((state) => state.user);
  const userById = useUsersStore((state) => state.documentById);
  const fetchUser = useUsersStore((state) => state.fetchDocument);
  const setPromptProps = useUIStore((state) => state.setPromptProps);
  const event = useEventsStore((state) => state.documentById[eventId ?? ""]);
  const deleteEvent = useEventsStore((state) => state.deleteDocument);
  const league = useLeaguesStore((state) => state.documentById[event?.leagueId ?? ""]);
  const areas = useAreasStore((state) => state.getDocumentsByKey(eventId));
  const areaById = useAreasStore((state) => state.documentById);
  const fetchAreas = useAreasStore((state) => state.fetchList);
  const records = useRecordsStore((state) => state.getDocumentsByKey(eventId));
  const { openEditEventForm } = useEventForm(league?.id);
  const { openNewAreaForm } = useAreaForm(league?.id, eventId);
  useGetRequisites(event?.leagueId, eventId);

  const { canEdit, isOwner, canView } = usePermissions(league);

  useEffect(() => {
    if (eventId) {
      const where = createWhereClause("eventId", "==", eventId);
      fetchAreas(where);
    }
  }, [eventId, fetchAreas]);

  useEffect(() => {
    if (event) {
      fetchUser(event.createdBy);
    }
  }, [event, fetchUser]);

  const addArea = () => {
    openNewAreaForm();
  };

  const editEvent = () => {
    if (!event) return;

    openEditEventForm(event);
  };

  const removeEvent = async () => {
    if (!event || !league || !eventId) return;

    const prompt = {
      title: `Delete ${event.name}`,
      message: `Are you sure you want to delete this event?\nType '${event.name}' to confirm.`,
      onConfirm: async (val: string) => {
        if (val !== event.name) return;

        await deleteEvent(eventId);
        console.log("Deleted Event");
        navigate("/league/" + league.id);
      },
    };
    setPromptProps(prompt);
  };

  const createCsv = () => {
    createRecordsCsv(records, areaById);
  };

  const viewPlayer = () => {
    if (!eventId) return;
    navigate(`/event/${eventId}/player`);
  };

  if (!league || !event || !user)
    return (<CenteredLoading />);

  if(!canView)
    return (<NotFound>Access Denied</NotFound>);

  if (!canEdit)
    return <EvaluatorEventView />;

  return (
    <ScrollingView>
      <Header title={event.name} />
      <Breadcrumb leagueId={league.id} eventId={event.id} />
      <AreasTable
        areas={areas}
        onAddClick={canEdit ? addArea : undefined}
        onRowClick={(i) => navigate(`/area/${areas[i].id}`)}
      />

      <ActionButtonBar>
        <ActionButton title="View All Records For Single Player" onClick={viewPlayer} />
      </ActionButtonBar>

      {canEdit && <PropertiesTable
        title='Event Details'
        properties={[
          { name: "Event Name", value: event.name },
          { name: "League Name", value: league.name },
          { name: "Event Date", value: timeStampToString(event.eventDate) },
          { name: "Event Created By", value: userById[event.createdBy ?? ""]?.name ?? "--" },
          { name: "Event Created At", value: timeStampToString(event.createdAt) },
          { name: "Bib Number Range", value: rangeToString(event.bibNumberRange) },
        ]}
      />}
      
      {canEdit && <ActionButtonBar>
          <ActionButton title="Edit Event" onClick={editEvent} />
          {isOwner && <RemoveButton title="Delete Event" onClick={removeEvent} />}
      </ActionButtonBar>}
      {canEdit && <ActionButtonBar>
          <AddButton title="Export CSV" onClick={createCsv} />
      </ActionButtonBar>}
    </ScrollingView>
  );
}

export default EventView;
