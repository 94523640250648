import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useAuthStore } from "../store/authStore";
import { useUIStore } from "../store/uiStore";
import ActionButton from "./basic/ActionButton";
import ButtonGroup, { GroupButtonProps } from "./basic/ButtonGroup";
import Toggle from "./basic/Toggle";
import { getConfirmModalProps } from "./modals/GenericModal";
import { CancelButton } from "./modals/GenericPrompt";
import Topbar from "./Topbar";

const SettingsDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  display: flex;
  flex-direction: column;

  transform: translateY(-100%);
  transition: transform cubic-bezier(.5, 0, .5, 1) .3s;

  &.open {
    transform: translateY(0%);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  align-self: center;
  max-width: calc(100% - 20px);
  overflow: auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #666;
  padding: 10px 20px;
  border-radius: 10px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  flex: 1;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: center;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export enum InputFieldType {
  Text = "text",
  Number = "number",
  DateTime = "datetime",
  Color = "color",
  Checkbox = "checkbox",
  Dropdown = "dropdown",
  ReadOnly = "readonly",
}

function Settings() {
  const user = useAuthStore((state) => state.user);
  const logOut = useAuthStore((state) => state.logOut);
  const settingsOpen = useUIStore((state) => state.settingsOpen);
  const setSettingsOpen = useUIStore((state) => state.setSettingsOpen);
  const setModalProps = useUIStore((state) => state.setModalProps);
  const darkModeOverride = useUIStore((state) => state.darkModeOverride);
  const setDarkModeOverride = useUIStore((state) => state.setDarkModeOverride);
  const forceEvaluatorView = useUIStore((state) => state.forceEvaluatorView);
  const setForceEvaluatorView = useUIStore((state) => state.setForceEvaluatorView);
  const [themeButtons, setThemeButtons] = useState<GroupButtonProps[]>([]);

  const handleCancel = () => {
    setSettingsOpen(false);
  };

  const handleLogOut = () => {
    const modal = getConfirmModalProps(
      "Log Out",
      "Are you sure you want to log out?",
      () => {
        logOut();
        setSettingsOpen(false);
      },
    );
    setModalProps(modal);
  };

  useEffect(() => {
    const buttons: GroupButtonProps[] = [];
    buttons.push({ title: "System", });
    buttons.push({ title: "Light" });
    buttons.push({ title: "Dark" });
    setThemeButtons(buttons);
  }, [darkModeOverride]);

  return (
    <SettingsDiv className={settingsOpen ? "open" : ""}>
      <Topbar>
        <Title>Settings</Title>
        <CancelButton onClick={handleCancel} title="Cancel">X</CancelButton>
      </Topbar>
      <Content>
        <Section>
          <ContentRow>
            Color Theme
            <ButtonGroup
              value={darkModeOverride === undefined ? "System" : (darkModeOverride ? "Dark" : "Light")}
              onClick={(val) => setDarkModeOverride(val === "System" ? undefined : val === "Dark")}
              buttons={themeButtons} />
          </ContentRow>
        </Section>

        <Section>
          <ContentRow>
            Evaluator View
            <Toggle checked={forceEvaluatorView} onChecked={() => setForceEvaluatorView(!forceEvaluatorView)} />
          </ContentRow>
        </Section>

        {user && <Section>
          <ContentRow>
            Logged in as:
            <b style={{textAlign: "left"}}>{user?.displayName ?? ""}<br />{user?.email ?? ""}</b>
          </ContentRow>
          <ActionButton onClick={handleLogOut} title="Log Out" />
        </Section>}
      </Content>
    </SettingsDiv>
  );
}

export default Settings;
