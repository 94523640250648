import { useNavigate, useParams } from 'react-router-dom';
import CenteredLoading from '../basic/CenteredLoading';
import { useAuthStore } from '../../store/authStore';
import { useAreasStore, useEventsStore, useLeaguesStore, useUsersStore } from "../../store/fireStore";
import { useEffect } from "react";
import { createWhereClause } from "../../firestore/firebase";
import { ScrollingView } from "../basic/View";
import Breadcrumb from '../tables/Breadcrumb';
import Header from '../basic/Header';
import AreasTable from '../tables/AreasTable';
import { useGetRequisites } from '../../firestore/firestoreHooks';

function EvaluatorEventView() {
  
  const navigate = useNavigate();
	const { eventId } = useParams<{ eventId: string }>();
  const user = useAuthStore((state) => state.user);
  const fetchUser = useUsersStore((state) => state.fetchDocument);
  const event = useEventsStore((state) => state.documentById[eventId ?? ""]);
  const league = useLeaguesStore((state) => state.documentById[event?.leagueId ?? ""]);
  const areas = useAreasStore((state) => state.getDocumentsByKey(eventId));
  const fetchAreas = useAreasStore((state) => state.fetchList);
  useGetRequisites(event.leagueId, eventId);

  useEffect(() => {
    if (eventId) {
      const where = createWhereClause("eventId", "==", eventId);
      fetchAreas(where);
    }
  }, [eventId, fetchAreas]);

  useEffect(() => {
    if (event) {
      fetchUser(event.createdBy);
    }
  }, [event, fetchUser]);

  if (!league || !event || !user)
    return (<CenteredLoading />);

  return (
    <ScrollingView>
      <Header title={event.name} />
      <Breadcrumb leagueId={league.id} eventId={event.id} />
      <AreasTable
        areas={areas}
        onRowClick={(i) => navigate(`/area/${areas[i].id}`)}
      />
    </ScrollingView>
  );
}

export default EvaluatorEventView;
