import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useUIStore } from '../../store/uiStore';
import ActionButton from '../basic/ActionButton';
import { CancelButton } from "./GenericPrompt";

const slideAnimLengthMs = 300;
const GenericModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  transition: background-color cubic-bezier(.5, 0, .5, 1) ${slideAnimLengthMs}ms;

  &.open {
  	background-color: #0008;
  }

`;

const GenericModalDiv = styled.dialog`
  background-color: var(--background);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0 0 5px 5px #0006;

  transform: translateY(100vh);
  transition: transform cubic-bezier(.5, 0, .5, 1) ${slideAnimLengthMs}ms;

  &.open {
    transform: translateY(0%);
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  align-self: center;
  max-width: 100%;
`;

const Topbar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.div`
	text-align: left;
	font-weight: 500;
	font-size: 22px;
	flex: 1;
`;

const Message = styled.div`
  font-size: 1.2em;
  flex: 1;
  padding-bottom: 20px;
  white-space: pre-line;
`;

const ColoredActionButton = styled(ActionButton)((props: { color: string }) => (`
	background: ${props.color}
`));

export interface GenericModalButtonProps {
  label: string;
  color?: string;
  onClick: (name: string) => void;
}

export interface GenericModalProps {
  title: string;
  message: string;
  buttons: GenericModalButtonProps[];
  onCancel?: () => void;
}

function GenericModal() {
  const modalProps = useUIStore((state) => state.modalProps);
  const setModalProps = useUIStore((state) => state.setModalProps);
  const [cachedProps, setCachedProps] = useState<GenericModalProps>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (modalProps) {
      setCachedProps(modalProps);
      setIsOpen(false);
    } else {
      setTimeout(() => setCachedProps(undefined), slideAnimLengthMs); // wait for animation to finish
      setIsOpen(false);
    }
  }, [modalProps]);

  useEffect(() => {
    if (cachedProps) setTimeout(() => setIsOpen(true), 10); // Delay to allow animation to start
  }, [cachedProps]);

  if (!cachedProps) return null;
  const { title, message, buttons, onCancel } = cachedProps;

  const handleCancel = () => {
	  setModalProps(undefined);
	  if (onCancel) onCancel();
  };

  return (
    <GenericModalOverlay className={isOpen ? "open" : ""} onClick={handleCancel}>
		<GenericModalDiv className={isOpen ? "open" : ""}>
			<Topbar>
				<Title>{title}22</Title>
				<CancelButton onClick={handleCancel} title="Cancel">X</CancelButton>
			</Topbar>
			<ModalContent>
				<Message>{message}</Message>
				{buttons.map((button) => (
					<ColoredActionButton
						key={button.label}
						onClick={() => {
							setModalProps(undefined);
							button.onClick(button.label);
						}}
						title={button.label}
						color={button.color ?? ""}
					>
						{button.label}
					</ColoredActionButton>
				))}
			</ModalContent>
		</GenericModalDiv>
	</GenericModalOverlay>
  );
}

export default GenericModal;

export const getConfirmModalProps = (title: string, message: string, onConfirm: () => void): GenericModalProps => ({
	  title,
	  message,
	  buttons: [
		{
			label: "OK",
			onClick: () => onConfirm(),
		},
		{
			label: "Cancel",
			color: "var(--buttonRemove)",
			onClick: () => {},
		},
	  ],
	  onCancel: () => {},
});
