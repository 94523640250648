import { useEffect } from "react";
import { useUIStore } from "../../store/uiStore";

interface Props {
	title: string;
	windowTitleOverride?: string;
}

function Header({ title, windowTitleOverride }: Props) {
	const setTitle = useUIStore(state => state.setTitle);

	useEffect(() => {
		if (title) {
			setTitle(title);
			window.document.title = (windowTitleOverride ?? title) + " - bballeval";
		}
	}, [title, windowTitleOverride, setTitle]);

	return null;
}

export default Header;
