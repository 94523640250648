import { useParams } from 'react-router-dom';
import CenteredLoading from '../basic/CenteredLoading';
import { useAuthStore } from '../../store/authStore';
import { useAreasStore, useEventsStore, useLeaguesStore, useRecordsStore, useUsersStore } from "../../store/fireStore";
import { useEffect, useState } from "react";
import { createWhereClause } from "../../firestore/firebase";
import { View } from "../basic/View";
import { Title } from "../basic/Title";
import Breadcrumb from '../tables/Breadcrumb';
import { useRecordForm } from '../forms/RecordForm';
import Header from '../basic/Header';
import TileList from '../basic/TileList';
import Tile from '../basic/Tile';
import FastScroll, { Section } from '../basic/FastScroll';
import { AreaType, RecordDef } from '../../types/firestoreTypes';
import { AreaInput, InputFieldProps, InputFieldType } from '../forms/InputForm';
import styled from 'styled-components/macro';
import Footer from '../Footer';
import { renderScore } from '../tables/RecordsTable';
import { useGetRequisites } from '../../firestore/firestoreHooks';

const TopView = styled(View)`
  flex: unset;
  max-height: 40%;
  overflow: auto;
`;

const Bottom = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const SubScrollView = styled(View)`
  flex: 1;
  overflow: auto;
  padding: 0 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0;
  width: 100%;
`;

const RatingDisplay = styled.div`
  font-size: 1rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  input {
    margin: 2px;
  }
`;

const ScoreTile = styled(Tile)`
  &.completed {
    box-shadow: inset 0px 0px 8px 3px var(--textColor);
  }
  &.other {
    opacity: 0.2;
  }
  &.current {
    background-color: #ffffff;
    color: #000;
  }
`;

function EvaluatorAreaView() {
	const { areaId } = useParams<{ areaId: string }>();
  const user = useAuthStore((state) => state.user);
  const fetchUser = useUsersStore((state) => state.fetchDocument);
  const area = useAreasStore((state) => state.documentById[areaId ?? ""]);
  const event = useEventsStore((state) => state.documentById[area?.eventId ?? ""]);
  const league = useLeaguesStore((state) => state.documentById[area?.leagueId ?? ""]);
  const records = useRecordsStore((state) => state.getDocumentsByKey(areaId));
  const fetchRecords = useRecordsStore((state) => state.fetchList);
  const { openEditRecordForm, openNewRecordForm } = useRecordForm(league?.id, event?.id, area);
  const [bibNumbers, setBibNumbers] = useState<string[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  useGetRequisites(area.leagueId, area.eventId, areaId);
  const [recordsByBibNumber, setRecordsByBibNumber] = useState<{ [bibNumber: string]: RecordDef }>({});

  const [field, setField] = useState<InputFieldProps>();

  const [edittingRecord, setEdittingRecord] = useState<Partial<RecordDef> | undefined>();

  useEffect(() => {
    if (area.type === AreaType.Rating)
      setField({ label: "Rating", type: InputFieldType.Rating, propName: "rating", range: area.range });
    else if (area.type === AreaType.Time)
      setField({ label: "Time", type: InputFieldType.Duration, propName: "rating", range: area.range });
    else if (area.type === AreaType.MultiChoice)
      setField({ label: "Choice", type: InputFieldType.Checkboxes, propName: "rating", options: area.options });
    else if (area.type === AreaType.SingleChoice)
      setField({ label: "Choice", type: InputFieldType.Dropdown, propName: "rating", options: area.options });
  }, [area]);

  const handleChange = (field: InputFieldProps, value: any) => {
    const newRecord: Partial<RecordDef> = { ...edittingRecord };
    const asObj = newRecord as any;
    asObj[field.propName] = value;
    setEdittingRecord(newRecord);
  };

  useEffect(() => {
    if (records) {
      const newRecordsByBibNumber: { [bibNumber: string]: RecordDef } = {};
      records.forEach((record) => {
        newRecordsByBibNumber[record.bibNumber] = record;
      });
      setRecordsByBibNumber(newRecordsByBibNumber);
    }
  }, [records]);

  useEffect(() => {
    if (event) {
      const frequency = 50;
      const bibNumbers = [];
      const [min, max] = event.bibNumberRange ?? [1, 100];
      const newSections = [];
      newSections.push({ title: min + "", elementId: "tile" + min });
      for (let i = min; i <= max; i++) {
        bibNumbers.push(i.toString());
        if (i % frequency === 0) newSections.push({ title: i + "", elementId: "tile" + i });
      }
      setSections(newSections);
      setBibNumbers(bibNumbers);
    }
  }, [event]);

  useEffect(() => {
    if (areaId) {
      const where = createWhereClause("areaId", "==", areaId);
      fetchRecords(where);
    }
  }, [areaId, fetchRecords]);

  useEffect(() => {
    if (area) {
      fetchUser(area.createdBy);
    }
  }, [area, fetchUser]);

  const editRecord = (bibNumber: string) => {
    const record = recordsByBibNumber[bibNumber];
    if (record) {
      if (record.evaluatorId !== user?.uid) return alert("You are not the evaluator for this record.");
      openEditRecordForm(record);
    } else openNewRecordForm({ ...edittingRecord, bibNumber });
  };

  if (!user || !area || !league || !event)
    return (<CenteredLoading />);

  return (
    <>
    <TopView>
      <Header title={area.name} />
      <Breadcrumb leagueId={league.id} eventId={event.id} areaId={area.id} />
      <Title>{area.name}</Title>
      {field && <InputWrapper>
        <AreaInput field={field} handleChange={(val) => handleChange(field, val)} />
      </InputWrapper>}
    </TopView>
    <Bottom>
      <FastScroll
        sections={sections}
        />
      <SubScrollView>
        <TileList>
          {bibNumbers.map((bibNumber, i) => {
            const record = recordsByBibNumber[bibNumber];
            return (
              <ScoreTile
                key={i}
                id={"tile" + bibNumber}
                title={record?.rating}
                className={record ? ("completed" + (record.evaluatorId === user.uid ? "" : " other")) : ""}
                onClick={() => editRecord(bibNumber)}
              >
                <>
                  {bibNumber}
                  <RatingDisplay>{renderScore(record?.rating, area.type)}</RatingDisplay>
                </>
              </ScoreTile>
            );
          })}
        </TileList>
        <Footer />
      </SubScrollView>
    </Bottom>
    </>
  );
}

export default EvaluatorAreaView;
