import styled from "styled-components/macro";
import { PropsWithChildren } from 'react';

const ActionButtonDiv = styled.button`
  background-color: var(--button);
  color: #fff;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
	transition: filter 0.2s;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  :hover {
    filter: brightness(1.1);
  }
  :active {
    filter: brightness(0.9);
  }
`;
const Content = styled.div`
  width: 100%;
  white-space: nowrap;
`;

interface Props {
  className?: string;
  title: string;
  disabled?: boolean;
  onClick: () => void;
}

function ActionButton({ children, disabled, className, title, onClick }: PropsWithChildren<Props>) {

  return (
    <ActionButtonDiv
      onClick={onClick}
      title={title}
      disabled={disabled}
      className={className}
      >
      <Content>
        {children ?? title}
      </Content>
    </ActionButtonDiv>
  );
}

export default ActionButton;


export const AddButton = styled(ActionButton)`
  background-color: var(--buttonAdd);
`;

export const RemoveButton = styled(ActionButton)`
  background-color: var(--buttonRemove);
`;