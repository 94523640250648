import React from 'react';
import { useAuthStore } from '../../store/authStore';
import CenteredLoading from '../basic/CenteredLoading';
import MyLeaguesView from './MyLeaguesView';


function AdminHome() {
  const user = useAuthStore((state) => state.user);
  
  if (!user) return (<CenteredLoading />);

  return (
    <MyLeaguesView />
  );
}

export default AdminHome;
