import React, { ReactElement } from "react";
import styled from "styled-components/macro";
//import config from "../config/config";
import pjson from "../../package.json";

const VersionDiv = styled.div`
	position: fixed;
	right: 1px;
	bottom: -1px;
	overflow: hidden;
	font-size: 10px;
`;

const VersionText = styled.span`
	color: #7b7b7b;
`;

function VersionDisplay(): ReactElement {
	const build = process.env.REACT_APP_BUILD ?? "local";
	const version = `${pjson.version}-${build}`;

	return (
		<VersionDiv className="noselect">
			<VersionText>
				<span>v{version}</span>
			</VersionText>
		</VersionDiv>
	);
}

export default VersionDisplay;
