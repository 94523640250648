import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { useInvitesStore, useUsersStore } from "../../store/fireStore";
import { useEffect } from "react";
import ActionButtonBar from "../basic/ActionButtonBar";
import ActionButton from "../basic/ActionButton";
import { useUIStore } from "../../store/uiStore";
import { ScrollingView } from "../basic/View";
import PropertiesTable from '../basic/PropertiesTable';
import {  httpsCallable } from "firebase/functions";
import { functions } from '../../firestore/firebase';
import Header from '../basic/Header';
import SignIn from '../auth/SignIn';
import NotFound from '../NotFound';
import styled from 'styled-components/macro';

const LargeActionButton = styled(ActionButton)`
  font-size: 1.5em;
  padding: 20px;
  margin-top: 50px;
`;

const acceptInvite = httpsCallable(functions, 'invites-acceptInvite');


function AcceptInviteView() {
  const navigate = useNavigate();
	const { inviteId } = useParams<{ inviteId: string }>();
  const user = useAuthStore((state) => state.user);
  const setLoadingOverlay = useUIStore((state) => state.setLoadingOverlay);
  const invite = useInvitesStore((state) => state.documentById[inviteId ?? ""]);
  const fetchInvite = useInvitesStore((state) => state.fetchDocument);
  const deleteInvite = useInvitesStore((state) => state.deleteDocument);
  const userById = useUsersStore((state) => state.documentById);
  const fetchUser = useUsersStore((state) => state.fetchDocument);
  
  useEffect(() => {
    if (inviteId) {
      fetchInvite(inviteId);
    }
  }, [inviteId, fetchInvite]);
  
  useEffect(() => {
    if (invite && invite.createdBy) {
      fetchUser(invite.createdBy);
    }
  }, [invite, fetchUser]);

  const handleAccept = async () => {
    if (!inviteId || !user) return;
    
    console.log("Accepting invite");

    setLoadingOverlay("Accepting Invite...");

    acceptInvite({ inviteId })
      .then((result) => {
        const data: any = result.data;
        console.log("Accepted invite!", data);
        const { status } = data;
        if (status === "success") {
          deleteInvite(inviteId); // Delete local copy. TODO fix this hack by using a better store
          navigate(`/league/${invite?.leagueId}`);
        } else {
          console.warn("Failed to accept invite!", data);
        }
        setLoadingOverlay(undefined);
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.warn("Failed to accept invite!", { code, message, details });
        setLoadingOverlay(undefined);
      });
  };

  if (!invite || !invite.email)
    return (<NotFound>Invite not found</NotFound>);
  
  if (!user)
    return (<SignIn>Please log in to accept your invitation!</SignIn>);

  if (user.email !== invite.email)
    return (<NotFound>This invite link is for a differnt email.<br /><br />Please log in with the same email that was invited.</NotFound>);

  return (
    <ScrollingView>
      <Header title="Accept Invite" />
      <PropertiesTable
        title='Invitation Details'
        properties={[
          { name: "League Name", value: invite.leagueName },
          { name: "Invited By", value: userById[invite.createdBy]?.name ?? "" },
        ]}
      />

      <ActionButtonBar>
          <LargeActionButton title="Accept Invite" onClick={handleAccept} />
      </ActionButtonBar>
    </ScrollingView>
  );
}

export default AcceptInviteView;
