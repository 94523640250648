import styled from "styled-components/macro";
import { useEffect, useState } from 'react';
import { useUIStore } from "../../store/uiStore";

const HasPendingWritesBanner = styled.div`
  background-color: #4b5262;
  color: #ffffff;
  padding: 0px;
  text-align: center;
  font-weight: 500;
  height: 0;
  overflow: hidden;
  transition: height 0.2s;

  &.show {
    height: 24px;
  }
  
  background-image: 
    repeating-linear-gradient(
      45deg, 
      transparent, 
      transparent 1rem,
      #464a51 1rem,
      #464a51 2rem
    );
  background-size: 200% 200%;
  animation: barberpole 10s linear infinite;
  
  @keyframes barberpole {
    100% {
      background-position: 100% 100%;
    }
  }
`;

function PendingWritesBanner() {
  const pendingWrites = useUIStore((state) => state.pendingWrites);
  const [displayedCount, setDisplayedCount] = useState(0);
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    if (pendingWrites > 0) setDisplayedCount(pendingWrites);
  }, [pendingWrites]);

  const handleOnline = () => {
    setOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("offline", handleOnline);
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("offline", handleOnline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);
  
  const classes = [];
  if (pendingWrites > 0) classes.push("show");
  if (online) classes.push("online");

  return (
    <HasPendingWritesBanner className={classes.join(" ")} >
      {displayedCount < 2 ? "Saving" : `Saving ${displayedCount} changes`}
    </HasPendingWritesBanner>
  );
}

export default PendingWritesBanner;
