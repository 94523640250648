import styled from "styled-components/macro";

const ButtonGroupDiv = styled.div`
  background-color: var(--button);
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  font-size: 16px;
	transition: filter 0.2s;
  white-space: nowrap;
  border: none;
`;

const ButtonDiv = styled.button`
  background-color: var(--button);
  color: #fff;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0;
  font-size: 16px;
	transition: filter 0.2s;
  white-space: nowrap;
  border: none;
  &.current {
    filter: brightness(0.8);
    box-shadow: inset 0 -2px 3px 3px #0003;
  }
  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export interface GroupButtonProps {
  title: string;
  disabled?: boolean;
}

interface Props {
  className?: string;
  value?: string;
  buttons: GroupButtonProps[];
  disabled?: boolean;
  onClick: (title: string) => void;
}

function ButtonGroup({ disabled, value, className, buttons, onClick }: Props) {

  return (
    <ButtonGroupDiv
      className={className}
      >
        {buttons.map((button, index) => (
          <ButtonDiv
            key={index}
            className={button.title === value ? "current" : ""}
            disabled={disabled || button.disabled}
            onClick={() => onClick(button.title)}
          >
            {button.title}
          </ButtonDiv>
        ))}
    </ButtonGroupDiv>
  );
}

export default ButtonGroup;
