import { doc, getDoc, setDoc, Timestamp } from '@firebase/firestore';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User, UserCredential } from 'firebase/auth';
import { create } from 'zustand'
import { auth, db } from '../firestore/firebase';
import { DbPushType, UserDef } from '../types/firestoreTypes';

interface AuthState {
  user?: User;
  loading: boolean;

  init: () => void;
  updateOurUser: (user: User) => Promise<void>;
  
  // logIn: (email: string, password: string) => Promise<UserCredential>;
  // signUp: (email: string, password: string) => Promise<UserCredential>;
  googleSignIn: () => Promise<UserCredential>;
  logOut: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()((set, get) => ({
  user: undefined,
  loading: true,
  init: () => {
    onAuthStateChanged(auth, (currentUser) => {
      // console.log("Got Auth", currentUser);
      set({ user: currentUser ?? undefined, loading: false });
      if (currentUser) get().updateOurUser(currentUser);
    });
  },
  updateOurUser: async (user: User) => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);

      // Get record
      const existing = await getDoc(userRef);

      // Only update lastLogin if it exists
      if (existing.exists()) {
        await setDoc(userRef, { lastLogin: Timestamp.now() }, { merge: true });
        // console.log("Updated User");
        return;
      }

      // Create new record
      const dbUser: DbPushType<UserDef> = {
        email: user.email ?? "",
        name: user.displayName ?? "",
        lastLogin: Timestamp.now(),
        createdAt: Timestamp.now(),
        createdBy: user.uid,
      };
      await setDoc(userRef, dbUser, { merge: true });
      // console.log("Created User");
    }
  },
  // logIn: async (email: string, password: string) => {
  //   const newUser = await signInWithEmailAndPassword(auth, email, password);
  //   return newUser;
  // },
  // signUp: async (email: string, password: string) => {
  //   const newUser = await createUserWithEmailAndPassword(auth, email, password);
  //   return newUser;
  // },
  googleSignIn: async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    const newUser = await signInWithPopup(auth, googleAuthProvider);
    return newUser;
  },
  logOut: () => {
    set({ user: undefined });
    return signOut(auth);
  },
}));

useAuthStore.getState().init();
