import { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components/macro";

const RatingInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const RatingButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  max-width: 100%;
`;

const RatingButton = styled.div`
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 1px solid var(--navbar);
  &.selected {
    background-color: var(--navbar);
    color: #fff;
    font-weight: bold;
  }
`;

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: var(--textColor);
`;

const Label = styled.div`
  
`;


interface Props {
  range: number[];
  rangeLabels: string[];
  value?: number;
  onChange: (value: number) => void;
}

// A input react component
export default function RatingInput({
  range,
  rangeLabels,
  value: initial,
  onChange,
}: Props) {
  const [value, setValue] = useState<number | undefined>(initial);
  const [options, setOptions] = useState<number[]>([]);
  const [scrolling, setScrolling] = useState(false);
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const blankArr = new Array(range[1] - range[0] + 1);
    const newOptions = blankArr.fill(0).map((_, i) => i + range[0]);
    setOptions(newOptions);
  }, [range]);


  const handleValueChanged = useCallback((newValue: number) => {
    if (value !== newValue) {
      onChangeRef.current(newValue)
      setValue(newValue);
    }
  }, [onChangeRef, value]);
  
  const handleMove = useCallback((x: number, y: number) => {
    let target = document.elementFromPoint(x, y);
    if (target && !target.getAttribute("data-rating")) target = target.parentElement;
    if (!target || !target.getAttribute("data-rating")) return;
    const rating = parseInt(target.getAttribute("data-rating") ?? "");
    if (!isNaN(rating)) {
      handleValueChanged(rating);
    }
  }, [handleValueChanged]);
  
  const onPointerMove = useCallback((e: TouchEvent) => {
    e.stopPropagation();
    handleMove(e.touches[0].clientX, e.touches[0].clientY);
  }, [handleMove]);

  const onMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault();
    handleMove(e.clientX, e.clientY);
  }, [handleMove]);

  useEffect(() => {
    if (!scrolling) return;
    // console.log("add listener");
    document.addEventListener("touchmove", onPointerMove);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onPointerUp);
    document.addEventListener("touchend", onPointerUp);
    return () => {
      // console.log("remove listener");
      document.removeEventListener("touchmove", onPointerMove);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onPointerUp);
      document.removeEventListener("touchend", onPointerUp);
    };
  }, [onMouseMove, onPointerMove, scrolling]);

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleMove(e.touches[0].clientX, e.touches[0].clientY);
    setScrolling(true);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleMove(e.clientX, e.clientY);
    setScrolling(true);
  };

  const onPointerUp = () => {
    setScrolling(false);
  };

  return (
    <RatingInputDiv>
      <Labels>
        <Label>{rangeLabels[0]}</Label>
        <Label>{rangeLabels[1]}</Label>
      </Labels>
      <RatingButtons onMouseDown={onMouseDown} onTouchStart={onTouchStart} className={scrolling ? "scrolling" : ""}>
        {options?.map((rating, i) => {
          return (
              <RatingButton
                key={i}
                className={value !== undefined && rating <= value ? "selected" : ""}
                title={"" + rating}
                data-rating={rating}
              >
                {rating}
              </RatingButton>
          )
        })}
      </RatingButtons>
    </RatingInputDiv>
  )
}