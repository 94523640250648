import { Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useUIStore } from '../../store/uiStore';
import ActionButton, { RemoveButton } from '../basic/ActionButton';
import FormInput from './FormInput';
import FormSelect from "./FormSelect";
import Topbar from '../Topbar';
import ArrayInput from "./ArrayInput";
import { CancelButton } from "../modals/GenericPrompt";
import IconAlertTriangle from "../icons/IconAlertTriangle";
import CheckboxesInput from "./CheckboxesInput";
import RatingInput from "./RatingInput";
import ImageInput, { PENDING_UPLOAD } from "../basic/ImageInput";
import { tryParseFloat } from "../../types/helpers";
import DurationInput from "./DurationInput";


const slideAnimLengthMs = 300;
const InputFormDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  display: flex;
  flex-direction: column;

  transform: translateY(100%);
  transition: transform cubic-bezier(.5, 0, .5, 1) ${slideAnimLengthMs}ms;

  &.open {
    transform: translateY(0%);
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  align-self: center;
  max-width: calc(100% - 20px);
  overflow: auto;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  flex: 1;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  
  &.error input {
    border-color: var(--error);
  }
`;

const Fill = styled.div`
  flex: 1;
`;

const InputLabel = styled.div`
  font-size: 1.2em;
  flex: 1;
  font-weight: bold;
  text-align: left;
`;

const ErrorDisplay = styled.div`
  color: var(--error);
  font-size: 0.8em;
  display: flex;
  gap: 5px;
  padding: 0 5px;
  align-items: center;
`;

export enum InputFieldType {
  Text = "text",
  Image = "image",
  Range = "range",
  RangeLabels = "rangeLabels",
  Rating = "rating",
  Array = "array",
  Number = "number",
  DateTime = "datetime",
  Color = "color",
  Checkboxes = "checkboxes",
  Dropdown = "dropdown",
  Duration = "duration",
  ReadOnly = "readonly",
}

export interface InputFieldProps {
  type: InputFieldType;
  label: string;
  placeholder?: string;
  value?: any;
  propName: string;
  error?: string;
  sanitize?: (props: InputFieldProps) => void; // Allows for parsing of input before saving
  validate?: (value: any) => string | undefined; // returns error message if invalid
  options?: string[];
  range?: number[];
  rangeLabels?: string[];
  fixedLength?: boolean;
  onChange?: (newValue: any, data: any) => void;
  renderExtra?: (newValue: any, data: any) => React.ReactElement | null;
}

// TODO hook off the field type
export const Validation = {
  required: (value: any) => {
    if (value === undefined || value === null || value === "") {
      return "Required";
    }
  },
  number: (value: any) => {
    if (isNaN(value)) {
      return "Must be a number";
    }
  },
  file: (value: any) => {
    if (value === PENDING_UPLOAD) {
      return "Please upload image first";
    }
  },
  range: (value: any) => {
    if (!Array.isArray(value)) {
      return "Must be an array";
    }
    for (const item of value) {
      if (item === undefined || item === null || typeof item === "string" || isNaN(item)) {
        return "Must be an array of numbers";
      }
    }
    // TODO consolidate number validation
    if (value[0] > value[1]) {
      return "Min must be less than max";
    }
  },
  rangeLabels: (value: any) => {
    if (!Array.isArray(value)) {
      return "Must be an array";
    }
    if (value.length !== 2) {
      return "Must be 2 values";
    }
    for (const item of value) {
      if (item === undefined || item === null || item === "") {
        return "Required";
      }
    }
  },
  requiredArray: (value: any) => {
    if (!Array.isArray(value)) {
      return "Must be an array";
    }
    for (const item of value) {
      if (item === undefined || item === null || item === "") {
        return "Required";
      }
    }
  },
  email: (value: any) => {
    if (!value.includes("@")) {
      return "Must be a valid email";
    }
  },
  url: (value: any) => {
    if (!value.startsWith("http")) {
      return "Must be a valid URL";
    }
  },
  date: (value: any) => {
    if (isNaN(Date.parse(value))) {
      return "Must be a valid date";
    }
  },
};

export const Sanitize = {
  range: (props: InputFieldProps) => {
    props.value = [tryParseFloat(props.value?.[0]), tryParseFloat(props.value?.[1])] as any;
  },
};

const createError = (error?: string) => {
  if (!error) return null;
  return <ErrorDisplay><IconAlertTriangle /> {error}</ErrorDisplay>;
};

const createTextInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { placeholder, label, value } = field;
  return (
    <FormInput
      value={value}
      onChange={value => handleChange(String(value))}
      placeholder={placeholder ?? label}
    />
  );
};

const createRangeInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value } = field;
  const [min, max] = value || [0, 10];
  return (
    <>
      <FormInput
        value={min}
        onChange={value => handleChange([value, max])}
        placeholder={"Minimum"}
      />
      <FormInput
        value={max}
        onChange={value => handleChange([min, value])}
        placeholder={"Maximum"}
      />
    </>
  );
};

const createArrayInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { placeholder, label, value, fixedLength } = field;
  return (
    <ArrayInput
      values={value}
      fixedLength={fixedLength}
      onChange={handleChange}
      placeholder={placeholder ?? label}
    />
  );
};

const createDateTimeInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value } = field;
  return (
    <FormInput
      type="datetime-local"
      value={value}
      onChange={value => handleChange(Timestamp.fromDate(new Date(value)))}
    />
  );
};

const createImageInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value } = field;
  return (
    <ImageInput
      path={value}
      onChange={handleChange}
    />
  );
};

const createDropdownInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value, options } = field;
  return (
    <FormSelect value={value} onChange={value => handleChange(String(value))}>
      {options?.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </FormSelect>
  );
};

const createCheckboxesInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value, options } = field;
  return (
    <CheckboxesInput
      options={options}
      values={value}
      onChange={handleChange}
    />
  );
};

const createRatingInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value, range, rangeLabels } = field;
  // if (!range || !rangeLabels) return null;
  return (
    <RatingInput
      range={range ?? [1, 10]}
      rangeLabels={rangeLabels ?? ["Weak", "Strong"]}
      value={value}
      onChange={handleChange}
    />
  );
};

const createDurationInput = (field: InputFieldProps, handleChange: (value: any) => void) => {
  const { value, range } = field;
  // const memoizedHandleChange = useCallback((value: any) => handleChange(value), []);
  return (
    <DurationInput
      value={value}
      range={range}
      onChange={handleChange}
    />
  );
};


const createReadOnly = (field: InputFieldProps) => {
  const { value } = field;
  return value;
};

const CellDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export function AreaInput({ field, handleChange }: { field: InputFieldProps, handleChange: (value: any) => void }) {
  const { type } = field;
  let input = <div>ERROR: Unknown field type!</div>;
  if (type === InputFieldType.ReadOnly) input = createReadOnly(field);
  if (type === InputFieldType.DateTime) input = createDateTimeInput(field, handleChange);
  if (type === InputFieldType.Dropdown) input = createDropdownInput(field, handleChange);
  if (type === InputFieldType.Checkboxes) input = createCheckboxesInput(field, handleChange);
  if (type === InputFieldType.Array) input = createArrayInput(field, handleChange);
  if (type === InputFieldType.Range) input = createRangeInput(field, handleChange);
  if (type === InputFieldType.Rating) input = createRatingInput(field, handleChange);
  if (type === InputFieldType.Duration) input = createDurationInput(field, handleChange);
  if (type === InputFieldType.Image) input = createImageInput(field, handleChange);
  if (type === InputFieldType.Text || type === InputFieldType.Number)
    input = createTextInput(field, handleChange);
  
  let extra = field.renderExtra?.(field.value, field);
  if (extra)
    return <CellDiv> {extra} {input} </CellDiv>;
  return input;
}





export interface InputFormProps<T> {
  formLabel: string;
  initial?: Partial<T>;
  createFields: (data: any) => InputFieldProps[];
  // onChange: (propName: string, value: any) => void;
  onSubmit: (data: T) => void;
  onCancel?: () => void;
  onDelete?: (data: T) => Promise<void>;
}

function InputForm() {
  const inputProps = useUIStore(state => state.inputProps);
  const setInputProps = useUIStore(state => state.setInputProps);

  const [cachedProps, setCachedProps] = useState<InputFormProps<any>>();
  const [data, setData] = useState<any>();
  const [fields, setFields] = useState<InputFieldProps[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (inputProps) {
      setCachedProps(inputProps);
      setData({ ...inputProps.initial });
      // const fields = inputProps.createFields(inputProps.initial);
      // setFields(fields);
      setIsOpen(false);
    } else {
      setTimeout(() => setCachedProps(undefined), slideAnimLengthMs); // wait for animation to finish
      setIsOpen(false);
    }
  }, [inputProps]);

  useEffect(() => {
    if (cachedProps) setTimeout(() => setIsOpen(true), 10); // Delay to allow animation to start
  }, [cachedProps]);

  const populateFields = (data: any, fieldsArr: InputFieldProps[]) => {
    if (data) {
      fieldsArr.forEach(f => {
        if (f.value === undefined && data[f.propName] !== undefined)
          f.value = data[f.propName]
      });
    }
  }

  useEffect(() => {
    if (!cachedProps) return;
    // console.log("data changed", data, cachedProps);
    const newFields = cachedProps.createFields(data);
    if (data) populateFields(data, newFields);
    setFields(newFields);
  }, [cachedProps, data]);

  useEffect(() => {
    if (cachedProps) setTimeout(() => setIsOpen(true), 10); // Delay to allow animation to start
  }, [cachedProps]);

  const handleChange = (field: InputFieldProps, value: any) => {
    const { propName } = field;
    if (value === data[propName]) return;
    const newData = { ...data, [propName]: value };
    if (field.onChange) field.onChange(value, newData);
    setData(newData);
  };

  if (!cachedProps) return null;
  const { formLabel, onCancel, onSubmit, onDelete } = cachedProps;

  const creating = !data.id;

  const validate = () => {
    let valid = true;
    for (const field of fields) {
      field.sanitize?.(field);
      if (field.validate) {
        field.error = field.validate(field.value);
        if (field.error) valid = false;
      }
    }
    setFields([...fields]);
    return valid;
  };

  const handleSubmit = () => {
    if (!validate()) return;
    onSubmit(data);
    setInputProps(undefined);
  };

  const handleDelete = async () => {
    onDelete?.(data);
  };

  const handleCancel = () => {
    setInputProps(undefined);
    onCancel?.();
  };

  const title = !creating ? 'Edit' : 'New';
  const action = !creating ? 'Update' : 'Create';

  return (
    <InputFormDiv className={isOpen ? "open" : ""}>
      <Topbar>
        <Title>{title} {formLabel}</Title>
        <CancelButton onClick={handleCancel} title="Cancel">X</CancelButton>
      </Topbar>
      <FormContent>
        
        {fields.map(field => {
          const { label, propName } = field;
          return (  
            <InputRow key={propName}>
            <InputLabel>{label}</InputLabel>
            <InputWrapper className={field.error ? "error" : ""}>
              <AreaInput field={field} handleChange={(val) => handleChange(field, val)} />
              {createError(field.error)}
            </InputWrapper>
          </InputRow>
        )})}
        <Fill />
        
        <ActionButton onClick={handleSubmit} title={`${action} ${formLabel}`} />
        {onDelete && <RemoveButton onClick={handleDelete} title={`Delete ${formLabel}`} />}
      </FormContent>
    </InputFormDiv>
  );
}

export default InputForm;
