import { useNavigate, useParams } from 'react-router-dom';
import CenteredLoading from '../basic/CenteredLoading';
import { useAuthStore } from '../../store/authStore';
import { useAreasStore, useEventsStore, useLeaguesStore, useRecordsStore } from "../../store/fireStore";
import { useEffect, useState } from "react";
import { createWhereClause } from "../../firestore/firebase";
import { ScrollingView } from "../basic/View";
import { Title } from "../basic/Title";
import Breadcrumb from '../tables/Breadcrumb';
import usePermissions from './PermissionsHook';
import Header from '../basic/Header';
import NotFound from '../NotFound';
import EvaluatorEventView from '../eval/EvaluatorEventView';
import DebouncedInput from '../basic/DebouncedInput';
import PlayerRecordsTable, { AreaRecord } from '../tables/PlayerRecordsTable';
import styled from 'styled-components/macro';
import { useRecordForm } from '../forms/RecordForm';
import { useGetRequisites } from '../../firestore/firestoreHooks';

const InputTitle = styled(Title)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: center;
  align-items: center;
  margin: 20px;
`;

function PlayerView() {
  const navigate = useNavigate();
	const { eventId, bibNumber } = useParams<{ eventId: string, bibNumber: string }>();
  const user = useAuthStore((state) => state.user);
  const event = useEventsStore((state) => state.documentById[eventId ?? ""]);
  const league = useLeaguesStore((state) => state.documentById[event?.leagueId ?? ""]);
  const areas = useAreasStore((state) => state.getDocumentsByKey(eventId));
  const fetchAreas = useAreasStore((state) => state.fetchList);
  const records = useRecordsStore((state) => state.getDocumentsByKey(eventId));
  const fetchRecords = useRecordsStore((state) => state.fetchList);
  const { canEdit, canView } = usePermissions(league);
  const [areaRecords, setAreaRecords] = useState<AreaRecord[]>([]);
  const { openEditRecordForm } = useRecordForm(league?.id, event?.id);
  useGetRequisites(event?.leagueId, eventId);
  

  const editRecord = (i: number) => {
    const areaRecord = areaRecords[i];
    // navigate(`/area/${areaRecord.area.id}`);
    if (areaRecord.record) openEditRecordForm(areaRecord.record);
  };

  useEffect(() => {
    const playerRecords = records.filter(r => r.bibNumber === bibNumber);
    const newAreaRecords: AreaRecord[] = [];
    areas.forEach((area) => {
      const record = playerRecords.find((r) => r.areaId === area.id);
      newAreaRecords.push({ area, record });
    });
    setAreaRecords(newAreaRecords);
  }, [records, bibNumber, areas]);


  useEffect(() => {
    if (eventId) {
      const where = createWhereClause("eventId", "==", eventId);
      fetchAreas(where);
    }
  }, [eventId, fetchAreas]);
  
  useEffect(() => {
    if (areas) {
      areas.forEach((area) => {
        const where = createWhereClause("areaId", "==", area.id);
        fetchRecords(where);
      });
    }
  }, [areas, fetchRecords]);

  const hasBibNumber = bibNumber !== "" && bibNumber !== undefined;

  const setBibNumber = (newBibNumber: string | number) => {
    const bibExtension = newBibNumber === undefined || newBibNumber === "" ? "" : "/" + newBibNumber;
    const newPath = `/event/${eventId}/player${bibExtension}`;
    if (window.location.pathname === newPath) return;
    navigate(newPath);
  };

  if (!league || !event || !user)
    return (<CenteredLoading />);

  if(!canView)
    return (<NotFound>Access Denied</NotFound>);

  if (!canEdit)
    return <EvaluatorEventView />;


  return (
    <ScrollingView>
      <Header title={event.name} windowTitleOverride={event.name + " - Player " + (bibNumber ?? "")} />
      <Breadcrumb leagueId={league.id} eventId={event.id} bibNumber={bibNumber ?? ""} />
      <InputTitle>
        Player
        <DebouncedInput
          value={bibNumber ?? ""}
          placeholder="Bib Number"
          onChange={setBibNumber}
        />
      </InputTitle>

      {!hasBibNumber && <CenteredLoading>Please enter a bib number</CenteredLoading>}

      {hasBibNumber && <PlayerRecordsTable
        records={areaRecords}
        onRowClick={editRecord}
      />}
      
    </ScrollingView>
  );
}

export default PlayerView;
