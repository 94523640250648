import { Timestamp } from "firebase/firestore";


export const tryParseFloat = (value?: string | number) => {
  if (value === undefined) return "";
  if (typeof value === "number") return value;
  if (value.match(/[^$,.\d]/)) return value;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? value : parsed;
};

export const rangeToString = (range?: number[]) => {
	return range ? `${range[0]} - ${range[1]}` : "--";
};

export const timeStampToString = (ts?: Timestamp) => {
	return ts?.toDate().toLocaleString() ?? "--";
};
