import styled from "styled-components/macro";
import { PropsWithChildren } from 'react';

const TileListDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

function TileList({ children }: PropsWithChildren) {
  return (
    <TileListDiv>
      {children}
    </TileListDiv>
  );
}

export default TileList;
