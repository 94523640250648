import React, { useCallback, useEffect, useState } from 'react'
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import styled from "styled-components/macro";
import DebouncedInput from '../basic/DebouncedInput';
import CenteredLoading from '../basic/CenteredLoading';
import ActionButton from '../basic/ActionButton';
import TablePagination, { pageSizes } from './TablePagination';
import Image from '../basic/Image';

const TableContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
	overflow: auto;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
	flex: 1;
	min-height: 200px;
	width: 100%;
`;

const Table = styled.table`
	border: none; // 1px solid lightgray;
	border-collapse: collapse;
	width: 100%;
	text-align: left;

	td {
		padding: 0 20px;
		height: 70px;
	}

	th {
		padding: 5px 15px;
	}
`;

const TopRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 10px;
	justify-content: space-between;
	gap: 10px;
`;

const TableHeader = styled.th`
	padding: 2px 4px;
	background-color: var(--background);
	filter: contrast(0.8);
	transition: filter 0.2s;
	.sortable {
		cursor: pointer;
		user-select: none;
		display: flex;
		flex-direction: row;
	}
	&:hover {
		filter: contrast(0.7);
	}
`;

const SortIcon = styled.div`
	text-align: right;
`;

const ClickableRow = styled.tr`
	cursor: pointer;
	transition: filter 0.2s;
	background-color: var(--background);

	&:hover {
		filter: contrast(0.95);
	}
	&:active {
		filter: contrast(0.85);
	}
	&:nth-child(even) {
		filter: contrast(0.9);
		&:hover {
			filter: contrast(0.85);
		}
		&:active {
			filter: contrast(0.8);
		}
	}
`;

export interface ReactTableCallback {
	onRowClick?: (i: number) => void;
	onAddClick?: () => void;
}

interface Props<T> extends ReactTableCallback {
	columns: ColumnDef<T, any>[],
	data: T[],
	addButtonLabel?: string,
	noResultsLabel?: string,
}

const filterFunc = (obj: any, lowercaseFilter: string) => {
	if (!lowercaseFilter) return true;
	for (const key in obj) {
		if (key === 'id') continue; // skip id (guid)
		const val = obj[key].toString().toLowerCase();
		if (val.includes(lowercaseFilter)) return true;
	}
	return false;
};

export const NameCell = styled.div`
	display: flex;
	align-items: center;
`;

export const CellImage = styled(Image)`
	width: 40px;
	height: 40px;
	margin-right: 10px;
`;

export default function ReactTable<T>({ columns, data, addButtonLabel, noResultsLabel, onAddClick, onRowClick }: Props<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [lowercaseFilter, setLowercaseFilter] = useState('');
  const [filteredData, setFilteredData] = useState<T[]>([]);

  const handleGlobalFilterChange = useCallback((val: string | number) => {
	setLowercaseFilter(val.toString().toLowerCase());
  }, []);

  useEffect(() => {
	const filteredData = data.filter(row => filterFunc(row, lowercaseFilter));
	setFilteredData(filteredData);
  }, [data, lowercaseFilter]);

  const table = useReactTable({
	data: filteredData,
	columns,
	getPaginationRowModel: getPaginationRowModel(),
	state: {
		sorting,
	},
	onSortingChange: setSorting,
	getCoreRowModel: getCoreRowModel(),
	getSortedRowModel: getSortedRowModel(),
	// debugTable: true,
  })

  return (
      <TableContainer>
		<Content>
			<TopRow>
				<DebouncedInput
					value={lowercaseFilter ?? ''}
					onChange={handleGlobalFilterChange}
					placeholder="Search..."
				/>
				{onAddClick && (
					<ActionButton title={addButtonLabel ?? "Add"} onClick={onAddClick} />
				)}
			</TopRow>
			<Table>
				<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
					{headerGroup.headers.map(header => {
						return (
						<TableHeader key={header.id} colSpan={header.colSpan}>
							{header.isPlaceholder ? null : (
							<div
								className={header.column.getCanSort() ? 'sortable' : ''}
								onClick={header.column.getToggleSortingHandler()}
							>
								{flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
								<SortIcon>
									{{
									asc: '⬆',
									desc: '⬇',
									}[header.column.getIsSorted() as string] ?? ""}
								</SortIcon>
							</div>
							)}
						</TableHeader>
						)
					})}
					</tr>
				))}
				</thead>
				<tbody>
				{table
					.getRowModel()
					.rows
					.map((row, i) => {
					return (
						<ClickableRow key={row.id} onClick={() => onRowClick && onRowClick(i)}>
						{row.getVisibleCells().map(cell => {
							return (
							<td key={cell.id}>
								{flexRender(
								cell.column.columnDef.cell,
								cell.getContext()
								)}
							</td>
							)
						})}
						</ClickableRow>
					)
					})}
				</tbody>
			</Table>
			{filteredData.length === 0 && <CenteredLoading>{noResultsLabel ?? "No results"}</CenteredLoading>}
			{filteredData.length > pageSizes[0] && <TablePagination table={table} />}
		</Content>
	  </TableContainer>
  )
}
