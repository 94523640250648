import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useEventsStore } from '../../store/fireStore';
import { EventDef } from '../../types/firestoreTypes';
import { useGenericForm } from './GenericForm';
import { InputFieldProps, InputFieldType, Sanitize, Validation } from './InputForm';


function datetimeLocal(datetime: Date) {
  const dt = new Date(datetime);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt.toISOString().slice(0, 16);
}

export const useEventForm = (leagueId?: string) => {
  const createEvent = useEventsStore((state) => state.createDocument);
  const updateEvent = useEventsStore((state) => state.updateDocument);
  const deleteEvent = useEventsStore((state) => state.deleteDocument);

  const [initial, setInitial] = useState<Partial<EventDef>>({});

  useEffect(() => {
    setInitial({ leagueId, bibNumberRange: [1, 399], eventDate: Timestamp.now(), });
  }, [leagueId]);

  const createFields = (event?: EventDef) => {
    const fields: InputFieldProps[] = [
      {
        label: 'Name',
        propName: 'name',
        type: InputFieldType.Text,
        validate: Validation.required,
      },
      {
        label: 'Event Date',
        value: datetimeLocal(event?.eventDate?.toDate() ?? new Date()),
        propName: 'eventDate',
        type: InputFieldType.DateTime,
      },
      {
        label: 'Bib Number Range',
        propName: 'bibNumberRange',
        value: event?.bibNumberRange,
        sanitize: Sanitize.range,
        type: InputFieldType.Range,
        validate: Validation.range,
      },
    ];
    return fields;
  };

  const { openEditForm, openNewForm } = useGenericForm<EventDef>({
    formLabel: "Event",
    initial,
    createFields,
    typeNameToDelete: true,
    createDocument: createEvent,
    updateDocument: updateEvent,
    deleteDocument: deleteEvent,
  });
  return { openNewEventForm: openNewForm, openEditEventForm: openEditForm };
};
