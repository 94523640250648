import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";

const ProtectedRoute = ({ children }: PropsWithChildren<{}>) => {
  const user = useAuthStore(state => state.user);
  const loading = useAuthStore(state => state.loading);

  if (!user && !loading) return <Navigate to="/" />;
  
  return <>
    {children}
  </>
};

export default ProtectedRoute;