import { deleteField, Timestamp } from '@firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useInvitesStore, useLeaguesStore } from '../../store/fireStore';
import { DbPushType, InviteDef, LeagueDef, RolesThatCanView, UserRole, ValidUserRole } from '../../types/firestoreTypes';
import { UserWithRole } from '../tables/UsersTable';
import { useGenericForm } from './GenericForm';
import { InputFieldProps, InputFieldType, Validation } from './InputForm';


export const useInviteUserForm = (league?: LeagueDef) => {
  const user = useAuthStore((state) => state.user);
  const addInvite = useInvitesStore((state) => state.createDocument);
  const deleteInvite = useInvitesStore((state) => state.deleteDocument);
  const updateLeague = useLeaguesStore((state) => state.updateDocument);

  const [initial, setInitial] = useState<Partial<UserWithRole>>({});

  useEffect(() => {
    setInitial({ role: UserRole.Viewer, isInvite: true });
  }, []);
  
  const createInviteUser = async (invitee: DbPushType<UserWithRole>) => {
    console.log("create invite1", league, invitee);
    if (!league) return "";
    if (!user?.uid) return "";
    if (!invitee.userEmail) return "";
    if (!invitee.role || !RolesThatCanView.includes(invitee.role)) return "";
    console.log("create invite", invitee);

    const invite: DbPushType<InviteDef> = {
      email: invitee.userEmail,
      role: invitee.role,
      leagueId: league.id,
      leagueName: league.name,
      createdAt: Timestamp.now(),
      createdBy: user.uid,
    };

    return await addInvite(invite);
  };

  const deleteUser = async (id: string) => {
    if (!league) return;
    console.log("deleting user from form", id);
    const newRoles = { ...league.roles };
    newRoles[id] = deleteField() as any;
    await updateLeague(league.id, {
      roles: newRoles,
    });
    console.log(league.roles, newRoles);

    const isInvite = false;
    if (isInvite) {
      await deleteInvite(id);
    }
  };

  const updateInviteUser = async (id: string, user: Partial<UserWithRole>) => {
    console.log("update user from form", id, user);
  };

  const createFields = (user?: UserWithRole) => {
    const fields: InputFieldProps[] = [
      {
        label: 'Email',
        propName: 'userEmail',
        type: user?.isInvite ? InputFieldType.Text : InputFieldType.ReadOnly,
        validate: Validation.required,
      },
      {
        label: 'Role',
        propName: 'role',
        type: InputFieldType.Dropdown,
        options: Object.values(ValidUserRole),
      },
    ];
    return fields;
  };

  const { openEditForm, openNewForm } = useGenericForm<UserWithRole>({
    formLabel: "Invite",
    initial,
    createFields,
    createDocument: createInviteUser,
    updateDocument: updateInviteUser,
    deleteDocument: deleteUser,
  });
  return { openNewInviteUserForm: openNewForm, openEditInviteUserForm: openEditForm };
};
