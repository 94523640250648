import React, { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import ReactTable, { NameCell, ReactTableCallback } from './ReactTable';
import { AreaDef } from '../../types/firestoreTypes';
import { useRecordsStore } from '../../store/fireStore';
import { createWhereClause } from '../../firestore/firebase';
import { AreaIcon, AreaIcons } from '../forms/AreaForm';

interface AreaRow {
	name: string;
	records: number;
	icon?: AreaIcons;
}
const columnHelper = createColumnHelper<AreaRow>()

const columns = [
	columnHelper.display({
		id: 'name',
		header: () => 'Area Name',
		cell: props => <NameCell>
				{<AreaIcon icon={props.row.original.icon} />}
				{props.row.original.name}
			</NameCell>,
	}),
	columnHelper.accessor('records', {
		header: () => 'Records',
	}),
];

interface Props extends ReactTableCallback {
	areas: AreaDef[];
}

export default function AreasTable({ areas, onRowClick, onAddClick }: Props) {
	const [data, setData] = useState<AreaRow[]>([]);
	const records = useRecordsStore(state => state.documents);
	const fetchRecords = useRecordsStore(state => state.fetchList);
	
	useEffect(() => {
		const areaIds = areas.map(a => a.id);
		if (areaIds.length === 0) return;
		const where = createWhereClause('areaId', 'in', areaIds);
		fetchRecords(where);
	}, [fetchRecords, areas]);

	useEffect(() => {
		if (!areas) return;
		const areaRows: AreaRow[] = areas.map(l => ({
			name: l.name,
			records: records.filter(r => r.areaId === l.id).length,
			icon: l.icon,
		}));
		setData(areaRows);
	}, [areas, records]);

	return <ReactTable
			columns={columns}
			data={data}
			addButtonLabel="+ Create Area"
			noResultsLabel="No Areas Found"
			onRowClick={onRowClick}
			onAddClick={onAddClick}
		/>
}
