import React, { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import ReactTable, { ReactTableCallback } from './ReactTable';
import { EventDef } from '../../types/firestoreTypes';
import { useAreasStore } from '../../store/fireStore';
import { createWhereClause } from '../../firestore/firebase';

interface EventRow {
	name: string;
	date: Date;
	areas: number;
}
const columnHelper = createColumnHelper<EventRow>()

const columns = [
	columnHelper.accessor('name', {
		header: () => 'Event Name',
	}),
	columnHelper.accessor('date', {
		header: () => 'Event Date',
		cell: (cell) => cell.getValue().toLocaleString(),
	}),
	columnHelper.accessor('areas', {
		header: () => 'Areas',
	}),
];

interface Props extends ReactTableCallback {
	events: EventDef[];
}

export default function EventsTable({ events, onRowClick, onAddClick }: Props) {
	const [data, setData] = useState<EventRow[]>([]);
	const areas = useAreasStore(state => state.documents);
	const fetchAreas = useAreasStore(state => state.fetchList);
	
	useEffect(() => {
		const eventIds = events.map(e => e.id);
		if (eventIds.length === 0) return;
		const where = createWhereClause('eventId', 'in', eventIds);
		fetchAreas(where);
	}, [fetchAreas, events]);

	useEffect(() => {
		if (!events) return;
		const eventRows = events.map(event => ({
			name: event.name,
			date: event.eventDate?.toDate() || new Date(),
			areas: areas.filter(a => a.eventId === event.id).length,
		}));
		setData(eventRows);
	}, [events, areas]);

	return <ReactTable
			columns={columns}
			data={data}
			addButtonLabel="+ Create Event"
			noResultsLabel="No Events Found"
			onRowClick={onRowClick}
			onAddClick={onAddClick}
		/>
}
