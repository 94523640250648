import styled from "styled-components/macro";
import Footer from "../Footer";

export const View = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1;
  gap: 10px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
`;

const ExpandingContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


export function ScrollingView({ children }: { children: React.ReactNode }) {
  return (
    <ScrollContainer>
      <ExpandingContent>
        <View>
          {children}
        </View>
      </ExpandingContent>
      <Footer />
    </ScrollContainer>
  );
}