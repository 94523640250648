import { useEffect } from 'react';
import { useInvitesStore, useLeaguesStore } from '../../store/fireStore';
import { useAuthStore } from '../../store/authStore';
import { createWhereClause } from '../../firestore/firebase';
import CenteredLoading from '../basic/CenteredLoading';
import LeaguesTable from '../tables/LeaguesTable';
import { ScrollingView } from '../basic/View';
import { useLeagueForm } from '../forms/LeagueForm';
import { RolesThatCanView } from '../../types/firestoreTypes';
import { useNavigate } from 'react-router-dom';
import Header from '../basic/Header';

function MyLeaguesView() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const leagues = useLeaguesStore((state) => state.documents);
  const fetchLeagues = useLeaguesStore((state) => state.fetchList);
  const { openNewLeagueForm } = useLeagueForm();
  const fetchInvites = useInvitesStore((state) => state.fetchList);

  useEffect(() => {
    if (!user) return;
    const whereClause = createWhereClause(`roles.${user.uid}`, "in", RolesThatCanView);
    fetchLeagues(whereClause);
  }, [user, fetchLeagues]);

  useEffect(() => {
    if (!user || !user.email) return;
    const whereClause = createWhereClause("email", "==", user.email);
    fetchInvites(whereClause);
  }, [fetchInvites, user]);


  const addLeague = () => {
    openNewLeagueForm();
  };

  const handleRowClick = (i: number) => {
    navigate("/league/" + leagues[i].id);
  };

  const handleInviteClicked = (inviteId: string) => {
    navigate("/invite/" + inviteId);
  };

  if (!user) return (<CenteredLoading />);

  return (
    <ScrollingView>
      <Header title="My Leagues" />
      
      <LeaguesTable
        leagues={leagues}
        onAddClick={addLeague}
        onRowClick={handleRowClick}
        onInviteClicked={handleInviteClicked}
        />
    </ScrollingView>
  );
}

export default MyLeaguesView;
