import { Table } from '@tanstack/react-table';
import styled from 'styled-components/macro';
import ActionButton from '../basic/ActionButton';
import FormSelect from '../forms/FormSelect';

const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
	flex-flow: wrap;
`;

const Chunk = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const PageDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

interface Props<T> {
	table: Table<T>;
}

export const pageSizes = [10, 25, 50, 100];

function TablePagination<T>({ table }: Props<T>) {
  const currentPage = table.getState().pagination.pageIndex;

  return (
    <PaginationDiv>
		<Chunk>
			<ActionButton
				className="border rounded p-1"
				onClick={() => table.setPageIndex(0)}
				disabled={!table.getCanPreviousPage()}
				title={`Go to first page`}
			>
				{'<<'}
			</ActionButton>
			<ActionButton
				className="border rounded p-1"
				onClick={() => table.previousPage()}
				disabled={!table.getCanPreviousPage()}
				title={`Go to previous page`}
			>
				{'<'}
			</ActionButton>
			<ActionButton
				className="border rounded p-1"
				onClick={() => table.nextPage()}
				disabled={!table.getCanNextPage()}
				title={`Go to next page`}
			>
				{'>'}
			</ActionButton>
			<ActionButton
				className="border rounded p-1"
				onClick={() => table.setPageIndex(table.getPageCount() - 1)}
				disabled={!table.getCanNextPage()}
				title={`Go to last page`}
			>
				{'>>'}
			</ActionButton>
		</Chunk>
		<Chunk>
			<PageDisplay>
				{`Page ${currentPage + 1} of ${table.getPageCount()}`}
			</PageDisplay>
			<FormSelect
				value={table.getState().pagination.pageSize}
				onChange={val => {
					table.setPageSize(Number(val))
				}}
				>
				{pageSizes.map(pageSize => (
					<option key={pageSize} value={pageSize}>
						Show {pageSize}
					</option>
				))}
			</FormSelect>
		</Chunk>

	</PaginationDiv>
  );
}

export default TablePagination;
