import React from 'react';
import SignIn from './auth/SignIn';
import AdminHome from './admin/AdminHome';
import { useAuthStore } from '../store/authStore';

function Home() {
  const user = useAuthStore((state) => state.user);

  if (!user) return <SignIn />;
  return <AdminHome />;
}

export default Home;
