import React, { useEffect, useState } from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import ReactTable, { ReactTableCallback } from './ReactTable';
import { AreaDef, AreaType, RecordDef } from '../../types/firestoreTypes';
import { useUsersStore } from '../../store/fireStore';
import { timeStampToString } from '../../types/helpers';
import { Checkbox } from '../forms/CheckboxesInput';
import styled from 'styled-components/macro';

interface RecordRow {
	bibNumber: string;
	score: string;
	createdAt: string;
	evaluator: string;
}
const columnHelper = createColumnHelper<RecordRow>();

const CheckboxRow = styled.div`
	font-size: 1rem;
	white-space: nowrap;
	display: flex;
	input {
		margin: 2px;
	}
`;

export const renderScore = (value: any, type?: AreaType) => {
	if (value === undefined) return;
	if (Array.isArray(value) && type === AreaType.MultiChoice) {
		return <CheckboxRow>{value.map((checked, i) => <Checkbox size={10} key={i} type="checkbox" checked={checked} readOnly />)}</CheckboxRow>;
	}
	// if (type === AreaType.Time) {
	// 	return value + "s";
	// }
	return value;
};

interface Props extends ReactTableCallback {
	area: AreaDef;
	records: RecordDef[];
}

export default function RecordsTable({ area, records, onRowClick,  onAddClick }: Props) {
	const [data, setData] = useState<RecordRow[]>([]);
	const userById = useUsersStore(state => state.documentById);
	const fetchUser = useUsersStore(state => state.fetchDocument);
	const [columns, setColumns] = useState<ColumnDef<RecordRow, string>[]>([]);

	useEffect(() => {
		const cols = [
			columnHelper.accessor('bibNumber', {
				header: () => 'Bib Number',
			}),
			columnHelper.accessor('score', {
				header: () => 'Score',
				cell: ({ renderValue }) => renderScore(renderValue(), area.type),
			}),
			columnHelper.accessor('evaluator', {
				header: () => 'Evaluator',
			}),
			columnHelper.accessor('createdAt', {
				header: () => 'Created',
			}),
		];
		setColumns(cols);
	}, [area]);

	useEffect(() => {
		if (!records) return;
		records.map(record => fetchUser(record.evaluatorId));
	}, [records, fetchUser]);

	useEffect(() => {
		if (!records) return;
		const recordRows = records.map(record => ({
			bibNumber: record.bibNumber,
			score: record.rating,
			createdAt: timeStampToString(record.createdAt),
			evaluator: userById[record.evaluatorId]?.name ?? record.evaluatorId,
		}));
		setData(recordRows);
	}, [records, userById]);

	return <ReactTable
			columns={columns}
			data={data}
			addButtonLabel="+ Create Record"
			noResultsLabel="No Records Found"
			onRowClick={onRowClick}
			onAddClick={onAddClick}
		/>
}
