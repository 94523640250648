import styled from "styled-components/macro";
import { PropsWithChildren } from 'react';

const ActionButtonBarDiv = styled.div`
  display: flex;    align-self: center;
  gap: 10px;
  padding: 10px;
`;

function ActionButtonBar({ children }: PropsWithChildren) {
  return (
    <ActionButtonBarDiv>
      {children}
    </ActionButtonBarDiv>
  );
}

export default ActionButtonBar;
