import React from 'react';
import styled from "styled-components/macro";
import { useUIStore } from '../store/uiStore';
import Topbar from './Topbar';
import BballEvalLogo from './BballEvalLogo';
import { useNavigate } from 'react-router-dom';
import IconSettingsSharp from './icons/IconSettingsSharp';

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
`;

const SmallLogo = styled(BballEvalLogo)`
  width: 40px;
  height: 40px;
  fill: #fff;
  cursor: pointer;
`;

const SettingsButton = styled.div`
  cursor: pointer;
`;

const SmallSettings = styled(IconSettingsSharp)`
  width: 30px;
  height: 30px;
  fill: #fff;
`;

function Navbar() {
  const navigate = useNavigate();
  const title = useUIStore((state) => state.title);
  const setSettingsOpen = useUIStore((state) => state.setSettingsOpen);
  const settingsOpen = useUIStore((state) => state.settingsOpen);

  return (
    <Topbar>
      <SmallLogo onClick={() => navigate("/")} />
      <Title>{title}</Title>
      <SettingsButton onClick={() => setSettingsOpen(!settingsOpen)} title="Settings">
        <SmallSettings />
      </SettingsButton>
    </Topbar>
  );
}

export default Navbar;
