import styled from "styled-components/macro";

const FooterDiv = styled.div`
  padding: 10px;
  font-size: 10px;
  color: #7b7b7b;
`;

function Footer() {
  return (<FooterDiv>bballeval © {new Date().getFullYear()}</FooterDiv>);
}

export default Footer;
