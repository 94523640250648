import { create } from 'zustand'
import { InputFormProps } from '../components/forms/InputForm';
import { GenericModalProps } from '../components/modals/GenericModal';
import { GenericPromptProps } from '../components/modals/GenericPrompt';
import { ThemeColors } from '../components/style/GlobalStyles';
import { localStorageGetBool, localStorageRemove, localStorageSetBool } from './localStorage';

const getIsDarkModeQuery = () => {
  return window.matchMedia?.('(prefers-color-scheme: dark)');
};

// Device color scheme changed
getIsDarkModeQuery()?.addEventListener('change', ({ matches }) => {
  useUIStore.getState().updateDarkMode();
});

const DARK_MODE_OVERRIDE_KEY = 'darkModeOverride';
const FORCE_EVALUATOR_VIEW_KEY = 'forceEvaluatorView';

interface UIState {
  title: string;
  setTitle: (title: string) => void;

  pendingWrites: number;
  setPendingWrites: (pendingWrites: number) => void;

  loadingOverlay?: string;
  setLoadingOverlay: (loadingOverlay?: string) => void;

  darkMode: boolean;
  updateDarkMode: () => void;

  darkModeOverride?: boolean; // undefined = system default
  setDarkModeOverride: (darkModeOverride?: boolean) => void;
  
  colorOverride?: ThemeColors;
  setColorOverride: (colorOverride?: ThemeColors) => void;

  forceEvaluatorView: boolean;
  setForceEvaluatorView: (forceEvaluatorView: boolean) => void;

  settingsOpen: boolean;
  setSettingsOpen: (settingsOpen: boolean) => void;

  inputProps?: InputFormProps<any>;
  setInputProps: (inputProps?: InputFormProps<any>) => void;

  modalProps?: GenericModalProps;
  setModalProps: (modalProps?: GenericModalProps) => void;

  promptProps?: GenericPromptProps;
  setPromptProps: (promptProps?: GenericPromptProps) => void;
}

export const useUIStore = create<UIState>()((set, get) => ({
  title: "",
  setTitle(title: string) {
    set({ title });
  },

  pendingWrites: 0,
  setPendingWrites(pendingWrites: number) {
    set({ pendingWrites });
  },

  loadingOverlay: undefined,
  setLoadingOverlay(loadingOverlay?: string) {
    set({ loadingOverlay });
  },

  darkMode: false,
  updateDarkMode() {
    const { darkModeOverride } = get();
    const darkMode = darkModeOverride ?? getIsDarkModeQuery()?.matches;
    set({ darkMode });
  },

  darkModeOverride: localStorageGetBool(DARK_MODE_OVERRIDE_KEY),
  setDarkModeOverride(darkModeOverride?: boolean) {
    set({ darkModeOverride });
    
    if (darkModeOverride === undefined) localStorageRemove(DARK_MODE_OVERRIDE_KEY);
    else localStorageSetBool(DARK_MODE_OVERRIDE_KEY, darkModeOverride);

    get().updateDarkMode();
  },

  forceEvaluatorView: localStorageGetBool(FORCE_EVALUATOR_VIEW_KEY) ?? false,
  setForceEvaluatorView(forceEvaluatorView: boolean) {
    set({ forceEvaluatorView });
    localStorageSetBool(FORCE_EVALUATOR_VIEW_KEY, forceEvaluatorView);
  },

  settingsOpen: false,
  setSettingsOpen(settingsOpen: boolean) {
    set({ settingsOpen });
  },

  colorOverride: undefined,
  setColorOverride(colorOverride?: ThemeColors) {
    set({ colorOverride });
  },

  inputProps: undefined,
  setInputProps(inputProps?: InputFormProps<any>) {
    set({ inputProps });
  },

  modalProps: undefined,
  setModalProps(modalProps?: GenericModalProps) {
    set({ modalProps });
  },

  promptProps: undefined,
  setPromptProps(promptProps?: GenericPromptProps) {
    set({ promptProps });
  },
}));

useUIStore.getState().updateDarkMode();
