import React, { useCallback, useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import ReactTable, { CellImage, NameCell, ReactTableCallback } from './ReactTable';
import { LeagueDef } from '../../types/firestoreTypes';
import { useAuthStore } from '../../store/authStore';
import { useEventsStore, useInvitesStore } from '../../store/fireStore';
import { createWhereClause } from '../../firestore/firebase';

interface LeagueRow {
	id: string;
	name: string;
	logoUrl?: string;
	myRole: string;
	users: string;
	events: string;
	isInvite: boolean;
}
const columnHelper = createColumnHelper<LeagueRow>()

const columns = [
	columnHelper.display({
		id: 'name',
		header: () => 'League Name',
		cell: props => <NameCell>
				{props.row.original.logoUrl && <CellImage path={props.row.original.logoUrl} />}
				{props.row.original.name}
			</NameCell>,
	}),
	columnHelper.accessor('events', {
		header: () => 'Events',
	}),
	columnHelper.accessor('users', {
		header: () => 'Users',
	}),
	columnHelper.accessor('myRole', {
		header: () => 'My Role',
	}),
];

interface Props extends ReactTableCallback {
	leagues: LeagueDef[];
	onInviteClicked: (inviteId: string) => void;
}

export default function LeaguesTable({ leagues, onRowClick, onAddClick, onInviteClicked }: Props) {
	const user = useAuthStore((state) => state.user);
	const [data, setData] = useState<LeagueRow[]>([]);
	const events = useEventsStore(state => state.documents);
	const fetchEvents = useEventsStore(state => state.fetchList);
	const invites = useInvitesStore(state => state.documents);
	const fetchInvites = useInvitesStore(state => state.fetchList);

	useEffect(() => {
		if (!user || !user.email) return;
		const where = createWhereClause('email', '==', user.email);
		fetchInvites(where);
	}, [user, fetchInvites]);

	const getEvents = useCallback((leagueId: string) => {
		const where = createWhereClause('leagueId', '==', leagueId);
		fetchEvents(where);
	  }, [fetchEvents]);

	useEffect(() => {
		if (!leagues || !user) return;

		leagues.forEach((league) => getEvents(league.id));
		const leagueRows: LeagueRow[] = leagues.map(league => ({
			id: league.id,
			name: league.name,
			logoUrl: league.imagePath,
			myRole: league.roles[user.uid] ?? "Invited",
			users: "" + Object.values(league.roles).length,
			events: "" + events.filter(e => e.leagueId === league.id).length,
			isInvite: false,
		}));
		invites.filter(invite => invite.email === user.email).forEach(invite => {
			leagueRows.push({
				id: invite.id,
				name: invite.leagueName,
				myRole: invite.role,
				users: "--",
				events: "--",
				isInvite: true,
			});
		});
		setData(leagueRows);
	}, [leagues, user, events, getEvents, invites]);

	const handleClick = (i: number) => {
		if (data[i].isInvite) onInviteClicked(data[i].id);
		else if (onRowClick) onRowClick(i);
	};

	return <ReactTable
			columns={columns}
			data={data}
			addButtonLabel="+ Create League"
			noResultsLabel="No Leagues Found"
			onRowClick={handleClick}
			onAddClick={onAddClick}
		/>
}
