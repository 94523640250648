import { useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firestore/firebase";
import styled from "styled-components/macro";
import { PENDING_UPLOAD } from "./ImageInput";

const ImageDiv = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    width: 100px;
    height: 100px;
    border-radius: 10%;
    background-color: #0001;
    background-image: url(${(props: { src?: string }) => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

interface Props {
    path?: string;
    className?: string
}
 
function Image({ path, className }: Props) {
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        if (path && path !== PENDING_UPLOAD) {
            const imageRef = ref(storage, path);
            getDownloadURL(imageRef).then((url) => {
                setImageUrl(url);
            });
        }
    }, [path]);

    if (!path) return null;
 
    return (
        <ImageDiv className={className} src={imageUrl} />
    );
}
 
export default Image;