import { useState, useEffect } from "react";
import { QueryFieldFilterConstraint } from "firebase/firestore";
import { createWhereClause, getCollectionSnapshot, getDocumentSnapshot } from "./firebase";
import { AreaDef, EventDef, DbType, RecordDef, LeagueDef, RolesThatCanOwn } from "../types/firestoreTypes";
import { useAuthStore } from "../store/authStore";
import { useAreasStore, useEventsStore, useLeaguesStore } from "../store/fireStore";

const cache = new Map<string, any>();

export function useGetCollection<T extends DbType>(dbName: string, whereClause?: QueryFieldFilterConstraint) {
	const [collectionData, setCollectionData] = useState<T[]>(cache.get(dbName + whereClause));
	const [loading, setLoading] = useState(!collectionData);
	const [error, setError] = useState(false);
	
	useEffect(() => {
		const unsubscribe = getCollectionSnapshot<T>(dbName, whereClause, (collectionArr, err) => {
			if (collectionArr) {
				collectionArr.forEach((document) => cache.set(dbName + "/" + document.id, document));
				console.log("fetched firestore collection", { dbName, collectionArr });
				setCollectionData(collectionArr);
				setLoading(false);
				setError(false);
			} else if (err) {
				setError(true);
			}
		});
	
		return unsubscribe;
	  }, [dbName, whereClause]);

	return [collectionData, loading, error] as const;
}

export function useGetDocument<T extends DbType>(dbName: string, documentId: string) {
	const [documentData, setDocumentData] = useState<T>(cache.get(dbName + documentId));
	const [loading, setLoading] = useState(!documentData);
	const [error, setError] = useState(false);
	
	useEffect(() => {
		if (!dbName || !documentId) return;
		const unsubscribe = getDocumentSnapshot<T>(dbName, documentId, (document, err) => {
			setLoading(false);
			if (document) {
				setDocumentData(document);
				cache.set(dbName + document.id, document);
				setError(false);
			} else if (err) {
				setError(true);
			}
		});
		return unsubscribe;
	  }, [dbName, documentId]);


	  return [documentData, loading, error] as const;
}

export function useGetLeagues() {
	const user = useAuthStore((state) => state.user);
	const [whereClause, setWhereClause] = useState<QueryFieldFilterConstraint>();
	useEffect(() => {
		if (!user?.uid) return;
		setWhereClause(createWhereClause(`roles.${user.uid}`, "in", RolesThatCanOwn));
	}, [user]);

	return useGetCollection<LeagueDef>("leagues", whereClause);
}

export function useGetLeague(leagueId: string) {
	return useGetDocument<LeagueDef>("leagues", leagueId);
}

export function useGetEvents(leagueId: string) {
	const [whereClause, setWhereClause] = useState<QueryFieldFilterConstraint>();
	useEffect(() => {
		setWhereClause(createWhereClause(`league`, "==", leagueId));
	}, [leagueId]);

	return useGetCollection<EventDef>("events", whereClause);
}

export function useGetEvent(eventId: string) {
	return useGetDocument<EventDef>("events", eventId);
}


export function useGetAreas(eventId: string) {
	return useGetCollection<AreaDef>(`events/${eventId}/areas`);
}


export function useGetArea(eventId: string, areaId: string) {
	return useGetDocument<AreaDef>(`events/${eventId}/areas`, areaId);
}

export function useGetRecords(eventId?: string, areaId?: string) {
	return useGetCollection<RecordDef>( `events/${eventId}/areas/${areaId}/records`);
}


// Kick off fetches to make sure were asking for data we need
export function useGetRequisites(leagueId?: string, eventId?: string, areaId?: string) {
	const fetchEvent = useEventsStore((state) => state.fetchDocument);
	const fetchLeague = useLeaguesStore((state) => state.fetchDocument);
	const fetchArea = useAreasStore((state) => state.fetchDocument);

	useEffect(() => {
		if (eventId) {
		  fetchEvent(eventId);
		}
	  }, [eventId, fetchEvent]);
	
	  useEffect(() => {
		if (areaId) {
		  fetchArea(areaId);
		}
	  }, [areaId, fetchArea]);
	
	  useEffect(() => {
		if (leagueId) {
		  fetchLeague(leagueId);
		}
	  }, [leagueId, fetchLeague]);
}