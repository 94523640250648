import { useState } from "react"
import styled from "styled-components/macro";
import { AddButton, RemoveButton } from "../basic/ActionButton";
import { StyledInput } from "./FormInput";

const ArrayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

interface Props {
  values: string[];
  fixedLength?: boolean;
  onChange: (values: string[]) => void;
  placeholder?: string;
}

// A input react component
export default function ArrayInput({
  values: initialValues,
  placeholder,
  fixedLength,
  onChange,
}: Props) {
  const [values, setValues] = useState<string[]>(initialValues);

  const handleValueChanged = (i: number, newValue: string) => {
	const existing = values[i];
    if (existing !== newValue) {
		const newValues = [...values];
		newValues[i] = newValue;
		onChange(newValues)
		setValues(newValues);
    }
  };

  const handleValuesChanged = (newValues: string[]) => {
    if (values !== newValues) {
      onChange(newValues)
      setValues(newValues);
    }
  };

  return (
	<ArrayContainer>
		{values?.map((value, i) => {
			return (
				<Row key={i}>
					<StyledInput value={value} placeholder={placeholder} onChange={e => handleValueChanged(i, e.target.value)} />
					{!fixedLength && values.length > 1 && <RemoveButton title="X" onClick={() => handleValuesChanged(values.filter((_, j) => j !== i))} />}
				</Row>
			)
		})}
		{!fixedLength && <AddButton title="Add Option" onClick={() => handleValuesChanged([...values, ""])} />}
	</ArrayContainer>
  )
}