import { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";

const FastScrollDiv = styled.div`
  background-color: var(--fastScrollBackground);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px 0 0 10px;
  text-align: left;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  color: #fff;
  user-select: none;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 5px 5px #0006;
  width: 20px;
  &.scrolling {
    width: 120px;
    padding-left: 10px;
  }
`;

const SectionDiv = styled.div`
  color: var(--textColor);
  text-align: center;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;

  div {
    width: 20px;
    padding: 2px 0px;
    border-radius: 5px;
  }

  transition: background-color 0.2s ease-in-out;
  &.current {
    background-color: #22222286;
  }
`;

export interface Section {
  elementId: string;
  title: string;
}

interface Props {
  sections: Section[];
}

let lastElement: HTMLElement | null = null;

function FastScroll({ sections }: Props) {
  const [scrolling, setScrolling] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const [currentElement, setCurrentElement] = useState<HTMLElement | null>(null);

  const handleScroll = useCallback((section: string) => {
    if (currentSection === section) return;
    const element = document.getElementById(section);
    if (element) {
      setCurrentSection(section);
      element.scrollIntoView({ behavior: "smooth" });
      setCurrentElement(element);
    }
  }, [currentSection]);

  useEffect(() => {
    if (lastElement) lastElement.classList.remove("current");
    currentElement?.classList.add("current");
    lastElement = currentElement;
  }, [currentElement]);

  const handleMove = useCallback((x: number, y: number) => {
    let target = document.elementFromPoint(x, y);
    if (target && !target.getAttribute("data-section")) target = target.parentElement;
    if (!target || !target.getAttribute("data-section")) return;
    const section = target.getAttribute("data-section");
    const sectionElement = sections.find((s) => s.title === section);
    if (sectionElement) {
      handleScroll(sectionElement.elementId);
    }
  }, [handleScroll, sections]);

  const onPointerMove = useCallback((e: TouchEvent) => {
    e.stopPropagation();
    handleMove(e.touches[0].clientX, e.touches[0].clientY);
  }, [handleMove]);

  const onMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault();
    handleMove(e.clientX, e.clientY);
  }, [handleMove]);

  useEffect(() => {
    if (!scrolling) return;
    // console.log("add listener");
    document.addEventListener("touchmove", onPointerMove);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onPointerUp);
    document.addEventListener("touchend", onPointerUp);
    return () => {
      // console.log("remove listener");
      document.removeEventListener("touchmove", onPointerMove);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onPointerUp);
      document.removeEventListener("touchend", onPointerUp);
    };
  }, [onMouseMove, onPointerMove, scrolling]);

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleMove(e.touches[0].clientX, e.touches[0].clientY);
    setScrolling(true);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleMove(e.clientX, e.clientY);
    setScrolling(true);
  };

  const onPointerUp = () => {
    setScrolling(false);
    setCurrentSection("");
    setCurrentElement(null);
  };

  if (sections.length === 0) return null;

  return (
    <FastScrollDiv onMouseDown={onMouseDown} onTouchStart={onTouchStart} className={scrolling ? "scrolling" : ""}>
      {sections.map((section, index) => (
        <SectionDiv
          key={index}
          data-section={section.title}
          className={section.elementId === currentSection ? "current" : ""}
        >
          <div>{section.title}</div>
        </SectionDiv>
      ))}
    </FastScrollDiv>
  );
}

export default FastScroll;
