import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useRecordsStore } from '../../store/fireStore';
import { AreaDef, AreaType, DbPushType, RecordDef } from '../../types/firestoreTypes';
import { timeStampToString } from '../../types/helpers';
import { useGenericForm } from './GenericForm';
import { InputFieldProps, InputFieldType, Validation } from './InputForm';


export const useRecordForm = (leagueId?: string, eventId?: string, area?: AreaDef) => {
  const user = useAuthStore((state) => state.user);
  const createRecord = useRecordsStore((state) => state.createDocument);
  const updateRecord = useRecordsStore((state) => state.updateDocument);
  const deleteRecord = useRecordsStore((state) => state.deleteDocument);

  const [initial, setInitial] = useState<Partial<RecordDef>>({});

  useEffect(() => {
    if (area) setInitial({ leagueId, eventId, areaId: area.id });
  }, [leagueId, eventId, area]);

  const createFields = (record?: RecordDef) => {
    const fields: InputFieldProps[] = [
      {
        label: 'Bib Number',
        propName: 'bibNumber',
        type: InputFieldType.Text,
        validate: Validation.number,
      },
    ];

    if (area?.type === AreaType.Rating) {
      fields.push({
        label: area.name,
        propName: 'rating',
        range: area.range,
        type: InputFieldType.Number,
        validate: Validation.number,
      });
    }

    if (area?.type === AreaType.Time) {
      fields.push({
        label: area.name,
        propName: 'rating',
        type: InputFieldType.Text,
        validate: Validation.number,
      });
    }

    if (area?.type === AreaType.Text) {
      fields.push({
        label: area.name,
        propName: 'rating',
        type: InputFieldType.Text,
        validate: Validation.required,
      });
    }

    if (area?.type === AreaType.Number) {
      fields.push({
        label: area.name,
        propName: 'rating',
        type: InputFieldType.Number,
        validate: Validation.required,
      });
    }
    
    if (area?.type === AreaType.SingleChoice) {
      fields.push({
        label: area.name,
        propName: 'rating',
        type: InputFieldType.Dropdown,
        options: area.options,
      });
    }
    
    if (area?.type === AreaType.MultiChoice) {
      fields.push({
        label: area.name,
        propName: 'rating',
        type: InputFieldType.Checkboxes,
        options: area.options,
      });
    }

    if (record?.id) {
      fields.push({
        label: 'Evaluator Name',
        propName: 'evaluatorName',
        type: InputFieldType.ReadOnly,
      })
      fields.push({
        label: 'Date',
        value: timeStampToString(record.createdAt),
        propName: 'createdAt',
        type: InputFieldType.ReadOnly,
      })
    }

    return fields;
  };

  const handleCreate = async (record: DbPushType<RecordDef>) => {
    if (!user) return "";
    record.evaluatorId = user.uid;
    record.evaluatorName = user.displayName ?? user.email ?? user.uid;
    return await createRecord(record);
  };

  const { openEditForm, openNewForm } = useGenericForm<RecordDef>({
    formLabel: "Record",
    initial,
    createFields,
    createDocument: handleCreate,
    updateDocument: updateRecord,
    deleteDocument: deleteRecord,
  });
  return { openNewRecordForm: openNewForm, openEditRecordForm: openEditForm };
};
