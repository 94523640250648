import { useEffect, useState } from 'react';
import { useAreasStore } from '../../store/fireStore';
import { AreaDef, AreaType } from '../../types/firestoreTypes';
import { useGenericForm } from './GenericForm';
import { InputFieldProps, InputFieldType, Sanitize, Validation } from './InputForm';
import styled from 'styled-components';


export enum AreaIcons {
  None = "None",
  Bunting = "Bunting",
  Catching = "Catching",
  Fielding = "Fielding",
  Groundball = "Groundball",
  Hitting = "Hitting",
  Pitching = "Pitching",
  Running = "Running",
  Throwing = "Throwing",
  Jersey = "Jersey",
}

const AreaIconImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  filter: invert(var(--invertIcons));
`;

export const AreaIcon = ({ icon }: { icon?: AreaIcons }) => {
  if (!icon || icon === AreaIcons.None) return null;
  return <AreaIconImg src={`/icons/${icon}.png`} alt={icon} />;
}

export const useAreaForm = (leagueId?: string, eventId?: string) => {
  const createArea = useAreasStore((state) => state.createDocument);
  const updateArea = useAreasStore((state) => state.updateDocument);
  const deleteArea = useAreasStore((state) => state.deleteDocument);

  const [initial, setInitial] = useState<Partial<AreaDef>>({});

  useEffect(() => {
    setInitial({ leagueId, eventId, type: AreaType.Rating, range: [1, 10], rangeLabels: ["Weak", "Strong"] });
  }, [leagueId, eventId]);

  const createFields = (area?: AreaDef) => {
    const creating = !area?.id;
    const fields: InputFieldProps[] = [
      {
        label: 'Name',
        propName: 'name',
        type: InputFieldType.Text,
        validate: Validation.required,
      },
      {
        label: 'Icon',
        propName: 'icon',
        type: InputFieldType.Dropdown,
        options: Object.values(AreaIcons),
        renderExtra: (icon: AreaIcons) => <AreaIcon icon={icon} />,
      },
      {
        label: 'Area Type',
        propName: 'type',
        type: creating ? InputFieldType.Dropdown : InputFieldType.ReadOnly,
        options: Object.values(AreaType),
        onChange: (val: AreaType, data: Partial<AreaDef>) => {
          if (val === AreaType.MultiChoice || val === AreaType.SingleChoice) {
            data.options = data.options ?? [""];
            delete data.range;
            delete data.rangeLabels;
          } else if (val === AreaType.Rating) {
            data.range = initial.range;
            data.rangeLabels = initial.rangeLabels;
            delete data.options;
          } else if (val === AreaType.Time) {
            data.range = [4.0, 16.0];
            delete data.rangeLabels;
            delete data.options;
          }
        }
      },
    ];

    if (area?.type === AreaType.MultiChoice || area?.type === AreaType.SingleChoice) {
      fields.push({
        label: 'Options',
        propName: 'options',
        placeholder: 'Enter option',
        type: InputFieldType.Array,
        validate: Validation.requiredArray,
      });
    } else if (area?.type === AreaType.Rating || area?.type === AreaType.Time) {
      fields.push({
        label: 'Range',
        propName: 'range',
        sanitize: Sanitize.range,
        type: InputFieldType.Range,
        validate: Validation.range,
      });
      fields.push({
        label: 'Range Labels',
        propName: 'rangeLabels',
        fixedLength: true,
        type: InputFieldType.Array,
        validate: Validation.rangeLabels,
      });
    }
    return fields;
  };

  const { openEditForm, openNewForm } = useGenericForm<AreaDef>({
    formLabel: "Area",
    initial,
    createFields,
    typeNameToDelete: true,
    createDocument: createArea,
    updateDocument: updateArea,
    deleteDocument: deleteArea,
  });
  return { openNewAreaForm: openNewForm, openEditAreaForm: openEditForm };
};
