import { Timestamp } from 'firebase/firestore';
import { useCallback } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useUIStore } from '../../store/uiStore';
import { DbPushType, DbType } from '../../types/firestoreTypes';
import { getConfirmModalProps } from '../modals/GenericModal';
import { InputFieldProps, InputFormProps } from './InputForm';

interface Props<T> {
  initial?: Partial<T>;
  formLabel: string;
  typeNameToDelete?: boolean;
  createFields: (existing?: T) => InputFieldProps[],
  createDocument: (document: DbPushType<T>) => Promise<string>,
  updateDocument: (id: string, changes: Partial<T>) => Promise<void>,
  deleteDocument?: (id: string) => Promise<void>,
}

export function useGenericForm<T extends DbType>({ initial, formLabel, typeNameToDelete, createFields, createDocument, updateDocument, deleteDocument }: Props<T>) {
  const user = useAuthStore((state) => state.user);
  const setInputProps = useUIStore((state) => state.setInputProps);
  const setModalProps = useUIStore((state) => state.setModalProps);
  const setPromptProps = useUIStore((state) => state.setPromptProps);

  const edit = useCallback(async (data: T) => {
    console.log("edit", formLabel, data.id, data);
    if (!data || JSON.stringify(data) === JSON.stringify(initial)) return console.log("nothing changed");
    
    data.updatedAt = Timestamp.now();

    await updateDocument(data.id, data);
    console.log("Updated", formLabel, data.id);
  }, [formLabel, initial, updateDocument]);
  
  const add = useCallback(async (data: T) => {
    if (!user) return;

    data.createdBy = user.uid;
    data.createdAt = Timestamp.now();

    const docId = await createDocument(data).catch(err => console.error("Error creating document", err));
    console.log("Created with ID: ", formLabel, docId);
  }, [createDocument, formLabel, user])

  const handleSubmit = useCallback((data: T) => {
    // TODO compare without the updatedAt field
    if (data.id) edit(data);
    else add(data);
  }, [add, edit]);

  const handleDelete = useCallback(async (data: T) => {
    if (!deleteDocument) return;
    
    const { id } = data;
    const name = (data as any).name ?? data.id.substring(0, 5);

    const title = `Delete ${formLabel}`;
    const message = `Are you sure you want to delete this ${formLabel}?`;

    const doDelete = async () => {
      await deleteDocument(id);
      console.log("Deleted ", formLabel, id);
      setInputProps(undefined);
    };

    if (typeNameToDelete) {
      const key = name ?? id.substring(0, 5);
      const prompt = {
        title,
        message: `${message}\nType '${key}' to confirm.`,
        onConfirm: async (val: string) => val === key && doDelete(),
      };
      setPromptProps(prompt);
    } else {
      const modal = getConfirmModalProps(
        title,
        message,
        doDelete
      );
      setModalProps(modal);
    }
  }, [deleteDocument, formLabel, typeNameToDelete, setInputProps, setPromptProps, setModalProps]);

  const openForm = (data?: Partial<T>) => {
    const props: InputFormProps<T> = {
      formLabel,
      initial: data,
      createFields,
      onSubmit: handleSubmit,
      onDelete: (deleteDocument && data?.id) ? handleDelete : undefined,
    };
    setInputProps(props);
  };

  const openNewForm = (data?: Partial<T>) => openForm({ ...initial, ...data } as Partial<T>);
  const openEditForm = (data: T) => openForm(data);

  return { openNewForm, openEditForm };
}
