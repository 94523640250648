import { AreaDef, RecordDef } from "./firestoreTypes";
import { timeStampToString } from "./helpers";


export const createRecordsCsv = (records: RecordDef[], areaById: {[key: string]: AreaDef}) => {
    const keys = ["date", "area", "name", "bibNumber", "rating"];
    const rows = records.map((record) => [
      timeStampToString(record.createdAt),
      areaById[record.areaId]?.name,
      record.evaluatorName,
      record.bibNumber,
      record.rating,
    ]);

	createCsv(keys, rows);
};

const sanitizeStr = (data: string) => data.replace(/["]/g, "");
const sanitize = (row: any[]) => row.map((cell) => {
    if (typeof cell === "object") return `"object"`;
    if (typeof cell === "string") return `"${sanitizeStr(cell)}"`;
    return cell.toString();
});

export const createCsv = async (keys: string[], rows: any[]) => {
  console.log("createCsv", rows);

  const csvContent = "data:text/csv;charset=utf-8," 
	+ keys.map(sanitizeStr).join(",") + "\n"
	+ rows.map(sanitize).map(e => e.join(",")).join("\n");

  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};