import styled from "styled-components/macro";
import { useUIStore } from '../store/uiStore';
import LoadingSpinner from "./LoadingSpinner";

const LoadingOverlayDiv = styled.div`
  color: #ffffff;
  background-color: #0008;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

function LoadingOverlay() {
	const loadingOverlay = useUIStore((state) => state.loadingOverlay);

	if (!loadingOverlay) return null;

	return (
		<LoadingOverlayDiv>
      <LoadingSpinner />
			{loadingOverlay}
		</LoadingOverlayDiv>
	);
}

export default LoadingOverlay;
