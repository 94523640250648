import { useEffect, useState } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firestore/firebase";
import { useAuthStore } from "../../store/authStore";
import { uuidv4 } from "@firebase/util";
import styled from "styled-components/macro";
import Image from "./Image";
import { deleteField, FieldValue } from "firebase/firestore";

const ImageWrapper = styled.div`
    position: relative;
  `;

const ClearButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    background: var(--buttonRemove);
    opacity: 0.8;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #fff;
    border-radius: 5px;
    height: 20px;
    font-size: 10px;
    width: 20px;
    line-height: 20px;
    user-select: none;
`;

const UploadButton = styled.label`
  background-color: var(--button);
  color: #fff;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  user-select: none;
  &.disabled {
    cursor: wait;
  }
  :hover {
    filter: brightness(1.1);
  }
  :active {
    filter: brightness(0.9);
  }
`;

const ProgressDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props: { percent: number }) => props.percent * 100}%;
    bottom: 0;
    background: #0008;
`;

export const PENDING_UPLOAD = "pending_upload.png";

interface Props {
    path?: string;
    onChange: (path?: string) => void;
}
 
function ImageInput({ path, onChange }: Props) {
    // State to store uploaded file
    const user = useAuthStore((state) => state.user);
 
    // progress
    const [percent, setPercent] = useState<number>();
    const [imagePath, setImagePath] = useState<string>();

    useEffect(() => {
      console.log("path", path);
      if (path as any instanceof FieldValue) setImagePath("");
      else if (path !== PENDING_UPLOAD) setImagePath(path);
    }, [path]);
 
    // Handle file upload event and update state
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const newFile = event.target.files?.[0];
      onChange(newFile ? PENDING_UPLOAD : undefined);
      handleUpload(newFile);
    }
 
    // Handle clear button
    function handleClear() {
      onChange(deleteField() as any);
    }
 
    const handleUpload = (file?: File) => {
        if (!user) return;
        if (!file) {
            return alert("Please upload an image first!");
        }

        const filename = uuidv4();// file.name;
        const path = `/user/${user.uid}/${filename}`;
 
        const storageRef = ref(storage, path);
 
        const uploadTask = uploadBytesResumable(storageRef, file);
        setPercent(0);
 
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = snapshot.bytesTransferred / snapshot.totalBytes;
                console.log("Upload is " + percent * 100 + "% done");
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                onChange(path);
                setPercent(undefined);
            }
        );
    };

    const disabled = percent !== undefined;
 
    return (
        <div>
            {!imagePath && (
              <UploadButton htmlFor="upload" className={disabled ? "disabled" : ""}>
                  {percent === undefined && "Choose file"}
                  {percent !== undefined && "Uploading..."}
                  {percent !== undefined && <ProgressDiv percent={percent} />}
                  <input type="file" id="upload" onChange={handleChange} accept="image/*" hidden disabled={disabled} />
                </UploadButton>
            )}
            {imagePath && <ImageWrapper>
              <Image path={imagePath} />
              <ClearButton onClick={handleClear} title="Clear">X</ClearButton>
            </ImageWrapper>}
        </div>
    );
}
 
export default ImageInput;