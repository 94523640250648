import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Home';
import Navbar from './Navbar';
import NotFound from './NotFound';
import ProtectedRoute from './auth/ProtectedRoute';
import VersionDisplay from './VersionDisplay';
import LeagueView from './admin/LeagueView';
import EventView from './admin/EventView';
import AreaView from './admin/AreaView';
import styled from 'styled-components/macro';
import InputForm from './forms/InputForm';
import GenericModal from './modals/GenericModal';
import GenericPrompt from './modals/GenericPrompt';
import { darkTheme, defaultTheme, GlobalStyle } from './style/GlobalStyles';
import { useUIStore } from '../store/uiStore';
import Settings from './Settings';
import AcceptInviteView from './admin/AcceptInviteView';
import LoadingOverlay from './LoadingOverlay';
import PlayerView from './admin/PlayerView';
import PendingWritesBanner from './basic/PendingWritesBanner';

const EvaluationModeBanner = styled.div`
  background-color: #bfa246;
  color: #000;
  padding: 0px;
  text-align: center;
  font-weight: 500;
`;

function App() {
  const darkMode = useUIStore((state) => state.darkMode);
  const forceEvaluatorView = useUIStore((state) => state.forceEvaluatorView);

  return (
    <Router>
      <GlobalStyle {...defaultTheme} />
      {darkMode && <GlobalStyle {...darkTheme } />}
      <Navbar />
      {forceEvaluatorView && <EvaluationModeBanner>Evaluator View</EvaluationModeBanner>}
      <PendingWritesBanner />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/invite/:inviteId" element={<AcceptInviteView />}></Route>
        <Route path="/league/:leagueId" element={<ProtectedRoute><LeagueView /></ProtectedRoute>}></Route>
        <Route path="/event/:eventId/player" element={<ProtectedRoute><PlayerView /></ProtectedRoute>}></Route>
        <Route path="/event/:eventId/player/:bibNumber" element={<ProtectedRoute><PlayerView /></ProtectedRoute>}></Route>
        <Route path="/event/:eventId" element={<ProtectedRoute><EventView /></ProtectedRoute>}></Route>
        <Route path="/area/:areaId" element={<ProtectedRoute><AreaView /></ProtectedRoute>}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <Settings />
      <InputForm />
      <GenericModal />
      <GenericPrompt />
      <LoadingOverlay />
      <VersionDisplay />
    </Router>
  );
}

export default App;
