import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components/macro";
import { useGetRequisites } from '../../firestore/firestoreHooks';
import { useAreasStore, useEventsStore, useLeaguesStore } from '../../store/fireStore';

const BreadcrumbDiv = styled.div`
  width: 100%;
  text-align: left;
  color: var(--textColor);
`;

const Spacer = styled.span`
  padding: 0 5px;
`;

interface Props {
  leagueId?: string;
  eventId?: string;
  areaId?: string;
  bibNumber?: string;
}

function Breadcrumb({ areaId, eventId, leagueId, bibNumber }: Props) {
  const leagueById = useLeaguesStore(state => state.documentById);
  const eventById = useEventsStore(state => state.documentById);
  const areaById = useAreasStore(state => state.documentById);
  
  useGetRequisites(leagueId, eventId, areaId);

  const crumbs: { label: string, to?: string }[] = [];
  crumbs.push({ label: "Home", to: "/" });
  if (leagueId) {
    crumbs.push({ label: ">" });
    const league = leagueById[leagueId];
    const label = league ? league.name : leagueId;
    crumbs.push({ label, to: "/league/" + leagueId });
  }
  if (eventId) {
    crumbs.push({ label: ">" });
    const event = eventById[eventId];
    const label = event ? event.name : eventId;
    crumbs.push({ label, to: "/event/" + eventId });
  }
  if (areaId) {
    crumbs.push({ label: ">" });
    const area = areaById[areaId];
    const label = area ? area.name : areaId;
    crumbs.push({ label, to: "/area/" + areaId });
  }
  if (eventId && bibNumber !== undefined) {
    crumbs.push({ label: ">" });
    const label = "Player " + bibNumber;
    crumbs.push({ label, to: "/event/" + eventId + "/player/" + bibNumber });
  }

  return (
    <BreadcrumbDiv>
      {crumbs.map(({ label, to }, index) => {
        if (to) return <Link key={index} to={to + window.location.search}>{label}</Link>;
        return <Spacer key={index}>{label}</Spacer>;
      })}
    </BreadcrumbDiv>
  );
}

export default Breadcrumb;
