import styled, { createGlobalStyle } from 'styled-components/macro';

export const Button = styled.button`
  background: #ccc3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 2px 12px;
`;

export interface ThemeColors {
  background?: string;
  navbar?: string;
  textColor?: string;
  error?: string;
  inputBackground?: string;
  inputTextColor?: string;
  toggleBackground?: string;

  tileBackground?: string;
  tileCompleted?: string;

  fastScrollBackground?: string;

  button?: string;
  buttonAdd?: string;
  buttonRemove?: string;
  buttonText?: string;

  invertIcons: string;
}

export const defaultTheme: ThemeColors = {
  background: '#fff',
  navbar: '#4a5262',
  textColor: '#000',
  error: '#e84e4e',
  inputBackground: '#fff',
  inputTextColor: '#000',
  toggleBackground: '#057dcd',

  tileBackground: '#c3c6cc',
  tileCompleted: '#a5acba',

  fastScrollBackground: '#c3c6cc',

  button: '#546b97',
  buttonAdd: '#239e4c',
  buttonRemove: '#ba381c',
  buttonText: '#fff',

  invertIcons: '0',
};

export const darkTheme: ThemeColors = {
  background: '#282c34',
  textColor: '#fff',
  inputBackground: '#282c34',
  inputTextColor: '#fff',
  tileBackground: '#606878',
  tileCompleted: '#9da7bb',
  fastScrollBackground: '#606878',

  invertIcons: '1',
};

export const GlobalStyle = createGlobalStyle((props: { [key: string]: string }) => {
  const overrides = Object.entries(props).map(([key, value]) => `--${key}: ${value};`).join('\r');
  return `
  :root {
    ${overrides};
  }
`});
