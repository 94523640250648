import { Timestamp } from "@firebase/firestore";
import { AreaIcons } from "../components/forms/AreaForm";

export enum ValidUserRole {
  Owner = "owner",
  Editor = "editor",
  Viewer = "viewer",
}

export enum UserRole {
  Owner = "owner",
  Editor = "editor",
  Viewer = "viewer",
  None = "none",
}

export const RolesThatCanOwn = [UserRole.Owner];
export const RolesThatCanEdit = [UserRole.Owner, UserRole.Editor];
export const RolesThatCanView = [
  UserRole.Owner,
  UserRole.Editor,
  UserRole.Viewer,
];

export const CanOwn = (role: UserRole) => RolesThatCanOwn.includes(role);
export const CanEdit = (role: UserRole) => RolesThatCanEdit.includes(role);
export const CanView = (role: UserRole) => RolesThatCanView.includes(role);

export interface InviteDef extends DbType {
  leagueName: string;
  leagueId: string;
  email: string;
  role: UserRole;
}

export interface LeagueDef extends DbType {
  name: string;
  roles: { [userId: string]: UserRole };
  imagePath?: string;
}

export interface EventDef extends DbType {
  eventDate: Timestamp;
  name: string;
  leagueId: string;
  bibNumberRange: number[];
}

export enum AreaType {
  Rating = "Rating",
  Time = "Time",
  SingleChoice = "SingleChoice",
  MultiChoice = "MultiChoice",
  Text = "Text",
  Number = "Number",
}

export interface AreaDef extends DbType {
  name: string;
  leagueId: string;
  eventId: string;
  icon?: AreaIcons;
  type: AreaType;
  options?: string[];
  range?: number[];
  rangeLabels?: string[];
}

export interface RecordDef extends DbType {
  bibNumber: string;
  rating: string;
  evaluatorId: string;
  evaluatorName: string;
  leagueId: string;
  eventId: string;
  areaId: string;
  notes?: string;
}

export interface UserDef extends DbType {
  email: string;
  name: string;
  lastLogin: Timestamp;
}

export interface DbType {
  id: string;
  createdBy: string;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
}

export interface ClientType {
  id: string;
  path: string;
}

export type DbPushType<T> = Omit<T, "id">;
