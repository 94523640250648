import React, { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import ReactTable, { ReactTableCallback } from './ReactTable';
import { DbType, UserDef, UserRole } from '../../types/firestoreTypes';
import { useAuthStore } from '../../store/authStore';

export interface UserWithRole extends DbType {
	user?: UserDef;
	userId?: string;
	userEmail?: string;
	role: UserRole;
	isInvite?: boolean;
};

interface UserRow {
	name: string;
	email: string;
	role: string;
}
const columnHelper = createColumnHelper<UserRow>()

const columns = [
	columnHelper.accessor('name', {
		header: () => 'Name',
	}),
	columnHelper.accessor('email', {
		header: () => 'Email',
	}),
	columnHelper.accessor('role', {
		header: () => 'Role',
	}),
];

interface Props extends ReactTableCallback {
	userRoles: UserWithRole[];
}

export default function UsersTable({ userRoles, onRowClick, onAddClick }: Props) {
	const user = useAuthStore((state) => state.user);
	const [data, setData] = useState<UserRow[]>([]);

	useEffect(() => {
		if (!userRoles || !user) return;
		const userRows = userRoles.map(u => ({
			name: u.user?.name ?? u.userId ?? "--",
			email: u.userEmail ?? u.user?.email ?? "--",
			role: u.role,
		}));
		setData(userRows);
	}, [userRoles, user]);

	return <ReactTable
			columns={columns}
			data={data}
			addButtonLabel="+ Invite User"
			noResultsLabel="No Users Found"
			onRowClick={onRowClick}
			onAddClick={onAddClick}
		/>
}
