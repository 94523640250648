import styled from "styled-components/macro";
import { Title } from "./Title";

const PropertiesTableDiv = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  table {
    border-collapse: collapse;
    border: none;
  }
  td {
    padding: 0.5rem;
  }
	tr:nth-child(even) {
    background-color: var(--background);
		filter: contrast(0.9);
	}
`;

export interface Property {
  name: string;
  value: string;
}

interface Props {
  properties: Property[],
  title?: string
}

export default function PropertiesTable({ properties, title }: Props) {
  return (
    <PropertiesTableDiv>
      {title &&<Title>{title}</Title>}
      <table>
        <tbody>
          {properties.map((p, i) => (
            <tr key={i}>
              <td>{p.name}</td>
              <td>{p.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PropertiesTableDiv>
  );
}
