import styled from "styled-components/macro";
import { PropsWithChildren } from 'react';

const CenteredLoadingDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;


function CenteredLoading({ children }: PropsWithChildren) {
  return (
    <CenteredLoadingDiv >
      {children ?? "Loading..."}
    </CenteredLoadingDiv>
  );
}

export default CenteredLoading;
