import { useCallback, useEffect, useState } from "react"
import { StyledInput } from "../forms/FormInput";

interface Props {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
}

// A debounced input react component
export default function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 400,
  ...props
}: Props & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState<string | number>("");

  const handleChanged = useCallback((val: any) => onChange(val), [onChange]);

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleChanged(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, debounce, handleChanged]);

  return (
    <StyledInput {...props} value={value} onChange={(e) => setValue(e.target.value)} />
  )
}