import React, { PropsWithChildren } from 'react';
import styled from "styled-components/macro";

const TopbarDiv = styled.div`
  background-color: var(--navbar);
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  height: 40px;
  align-items: center;
`;

function Topbar({ children }: PropsWithChildren) {
  return (
    <TopbarDiv>
      {children}
    </TopbarDiv>
  );
}

export default Topbar;
