import styled from "styled-components/macro";
import { PropsWithChildren } from 'react';

const TileDiv = styled.div`
  width: 100px;
  max-width: calc((100% - 40px) / 4);
  min-height: 50px;
  aspect-ratio: 3/2;
  background-color: var(--tileBackground);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  font-size: 24px;
	transition: filter 0.2s;
  overflow: hidden;
  cursor: pointer;
  :hover {
    filter: brightness(1.1);
  }
`;
const Content = styled.div`
  width: 100%;
`;

interface Props {
  id?: string;
  className?: string;
  title?: string;
  onClick: () => void;
}

function Tile({ id, title, className, children, onClick }: PropsWithChildren<Props>) {
  const classes = [];
  if (className) classes.push(className);

  return (
    <TileDiv id={id} title={title} onClick={onClick} className={classes.join(" ")}>
      <Content>
        {children}
      </Content>
    </TileDiv>
  );
}

export default Tile;
