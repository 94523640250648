import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { useEventsStore, useInvitesStore, useLeaguesStore, useUsersStore } from "../../store/fireStore";
import { useEffect, useState } from "react";
import { createWhereClause } from "../../firestore/firebase";
import ActionButtonBar from "../basic/ActionButtonBar";
import ActionButton, { RemoveButton } from "../basic/ActionButton";
import { useUIStore } from "../../store/uiStore";
import EventsTable from "../tables/EventsTable";
import { ScrollingView } from "../basic/View";
import { Title } from "../basic/Title";
import UsersTable, { UserWithRole } from "../tables/UsersTable";
import Breadcrumb from '../tables/Breadcrumb';
import { useLeagueForm } from '../forms/LeagueForm';
import { useEventForm } from '../forms/EventForm';
import CenteredLoading from '../basic/CenteredLoading';
import { useInviteUserForm } from '../forms/InviteUserForm';
import PropertiesTable from '../basic/PropertiesTable';
import usePermissions from './PermissionsHook';
import Header from '../basic/Header';
import { Timestamp } from 'firebase/firestore';
import { timeStampToString } from '../../types/helpers';
import NotFound from '../NotFound';
import Image from '../basic/Image';
import { useGetRequisites } from '../../firestore/firestoreHooks';

function LeagueView() {
	const { leagueId } = useParams<{ leagueId: string }>();
  const user = useAuthStore((state) => state.user);
  const userById = useUsersStore((state) => state.documentById);
  const fetchUser = useUsersStore((state) => state.fetchDocument);
  const setPromptProps = useUIStore((state) => state.setPromptProps);
  const league = useLeaguesStore((state) => state.documentById[leagueId ?? ""]);
  const deleteLeague = useLeaguesStore((state) => state.deleteDocument);
  const events = useEventsStore((state) => state.getDocumentsByKey(leagueId));
  const fetchEvents = useEventsStore((state) => state.fetchList);
  const invites = useInvitesStore((state) => state.getDocumentsByKey(leagueId));
  const fetchInvites = useInvitesStore((state) => state.fetchList);
  const navigate = useNavigate();
  const { openEditLeagueForm } = useLeagueForm();
  const { openNewEventForm } = useEventForm(leagueId);
  const { isOwner, canEdit, canView } = usePermissions(league);
  useGetRequisites(leagueId);

  const { openEditInviteUserForm, openNewInviteUserForm } = useInviteUserForm(league);

  const [userRoles, setUserRoles] = useState<UserWithRole[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<UserWithRole[]>([]);

  useEffect(() => {
    if (leagueId) {
      const whereClause = createWhereClause(`leagueId`, "==", leagueId);
      fetchEvents(whereClause);
      if (canEdit) fetchInvites(whereClause);
    }
  }, [leagueId, fetchEvents, fetchInvites, canEdit]);

  const addEvent = () => {
    openNewEventForm();
  };

  const addUser = () => {
    openNewInviteUserForm();
  };

  const removeLeague = async () => {
    if (!league || !leagueId) return;

    const prompt = {
      title: `Delete ${league.name}`,
      message: `Are you sure you want to delete this league?\nType '${league.name}' to confirm.`,
      onConfirm: async (val: string) => {
        if (val !== league.name) return;

        await deleteLeague(leagueId);
        console.log("Deleted league");
        navigate("/");
      },
    };
    setPromptProps(prompt);
  };

  const editLeague = () => {
    if (!league) return;
    
    openEditLeagueForm(league);
  };

  useEffect(() => {
    if (league) {
      Object.entries(league.roles).forEach(([userId]) => fetchUser(userId));
    }
  }, [league, fetchUser]);

  useEffect(() => {
    invites.forEach(({ createdBy }) => fetchUser(createdBy));
  }, [invites, fetchUser, league]);

  useEffect(() => {
    if (!league || !user) return;

    const roles: UserWithRole[] = Object.entries(league.roles).map(([userId, role]) => {
      const u = userById[userId];
      const userRole: UserWithRole = {
        id: userId,
        userEmail: u?.email,
        userId,
        user: u,
        role,
        createdAt: Timestamp.now(),
        createdBy: user.uid,
      };
      return userRole;
    });
    
    setUserRoles(roles);
  }, [user, league, userById]);

  useEffect(() => {
    if (!user) return;

    const roles = invites.map((invite) => {
      const userRole: UserWithRole = {
        id: invite.email,
        userEmail: invite.email,
        role: invite.role,
        createdAt: Timestamp.now(),
        createdBy: user.uid,
      };
      return userRole;
    });
    
    setInvitedUsers(roles);
  }, [user, invites]);

  if (!league || !user) return (<CenteredLoading />);

  if (!canView)
    return (<NotFound>Access Denied</NotFound>);

  return (
    <ScrollingView>
        <Header title={league.name} />
        <Breadcrumb leagueId={leagueId} />
        <Image path={league.imagePath} />
        <EventsTable 
          events={events}
          onAddClick={canEdit ? addEvent : undefined}
          onRowClick={(i) => navigate("/event/" + events[i].id)}
        />

        {canEdit && <PropertiesTable
          title='League Details'
          properties={[
            { name: "League Name", value: league.name },
            { name: "League Created By", value: userById[league.createdBy ?? ""]?.name ?? "--" },
            { name: "League Created At", value: timeStampToString(league.createdAt) },
          ]}
        />}

        {canEdit && <ActionButtonBar>
          <ActionButton title="Edit League" onClick={editLeague} />
          {isOwner && <RemoveButton title="Delete League" onClick={removeLeague} />}
        </ActionButtonBar>}

        {canEdit && <Title>Users</Title>}
        
        {canEdit && <UsersTable 
          userRoles={userRoles}
          onAddClick={addUser}
          onRowClick={(i) => openEditInviteUserForm(userRoles[i])}
        />}

        {canEdit && <Title>Pending Invites</Title>}
        
        {canEdit && <UsersTable 
          userRoles={invitedUsers}
          onRowClick={(i) => alert("Implement me!")}
        />}
    </ScrollingView>
  );
}

export default LeagueView;
