import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useUIStore } from '../../store/uiStore';
import FormInput from "../forms/FormInput";
import ActionButton from '../basic/ActionButton';

const slideAnimLengthMs = 300;
const GenericPromptOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: background-color cubic-bezier(.5, 0, .5, 1) ${slideAnimLengthMs}ms;

  &.open {
  	background-color: #0008;
  }

`;

const GenericPromptDiv = styled.dialog`
  background-color: var(--background);
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  max-height: 100%;
  max-width: 80%;
  box-shadow: 0 0 5px 5px #0006;

  transform: translateY(100vh);
  transition: transform cubic-bezier(.5, 0, .5, 1) ${slideAnimLengthMs}ms;

  &.open {
    transform: translateY(0%);
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  align-self: center;
  max-width: 100%;
  overflow: auto;
`;

const Topbar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.div`
	text-align: left;
	font-weight: 500;
	font-size: 22px;
	flex: 1;
`;

const Message = styled.div`
  font-size: 1.2em;
  flex: 1;
  padding-bottom: 20px;
  white-space: pre-line;
`;

export const CancelButton = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  background: #ccc;
  border-radius: 10px;
  padding: 4px 12px;
  transition: filter 0.2s ease-in-out;
  user-select: none;
  :hover {
    filter: brightness(1.1);
  }
  :active {
    filter: brightness(0.9);
  }
`;

export interface GenericPromptProps {
  title: string;
  message: string;
  initialValue?: string;
  placeholder?: string;
  onConfirm: (val: string) => void;
  onCancel?: () => void;
}

function GenericPrompt() {
  const promptProps = useUIStore(state => state.promptProps);
  const setPromptProps = useUIStore(state => state.setPromptProps);
  const [value, setValue] = useState("");
  const [cachedProps, setCachedProps] = useState<GenericPromptProps>();
  const [isOpen, setIsOpen] = useState(false);

  
  useEffect(() => {
    if (promptProps) {
      setCachedProps(promptProps);
      setIsOpen(false);
    } else {
      setTimeout(() => setCachedProps(undefined), slideAnimLengthMs); // wait for animation to finish
      setIsOpen(false);
    }
  }, [promptProps]);

  useEffect(() => {
    if (cachedProps) setTimeout(() => setIsOpen(true), 10); // Delay to allow animation to start
  }, [cachedProps]);

  if (!cachedProps) return null;
  const { title, message, placeholder, initialValue, onConfirm, onCancel } = cachedProps;

  const handleCancel = () => {
    setPromptProps(undefined);
    if (onCancel) onCancel();
  };

  return (
    <GenericPromptOverlay className={isOpen ? "open" : ""}>
      <GenericPromptDiv className={isOpen ? "open" : ""}>
        <Topbar>
          <Title>{title}</Title>
          <CancelButton onClick={handleCancel} title="Cancel">X</CancelButton>
        </Topbar>
        <ModalContent>
          <Message>{message}</Message>
          <FormInput
            value={initialValue ?? ""}
            onChange={(val) => setValue(val.toString())}
            placeholder={placeholder ?? "Enter value"}
          />
          <ActionButton title="Submit" onClick={() => {
            setPromptProps(undefined);
            onConfirm(value);
          }} />
        </ModalContent>
      </GenericPromptDiv>
    </GenericPromptOverlay>
  );
}

export default GenericPrompt;
