import { Unsubscribe } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, QueryFieldFilterConstraint, setDoc, Timestamp } from 'firebase/firestore';
import { create, StoreApi, UseBoundStore } from 'zustand'
import { db, getCollectionSnapshot, getDocumentSnapshot } from '../firestore/firebase';
import { AreaDef, DbPushType, EventDef, DbType, InviteDef, LeagueDef, RecordDef, UserDef } from '../types/firestoreTypes';

const storeCache: { [cacheId: string]: UseBoundStore<StoreApi<FireStoreState<any>>> } = {};

interface SnapshotCache {
  id: string;
  snapshot: Unsubscribe;
  documentIds?: string[];
}

interface FireStoreState<T> {
  dbName: string;
  keysToMap: string[];
  documents: T[];
  documentById: { [key: string]: T };
  documentMap: { [key: string]: T[] };
  snapshots: SnapshotCache[];
  setDocuments: (documents: T[]) => void;
  createDocument: (d: DbPushType<T>) => Promise<string>;
  updateDocument: (documentId: string, d: Partial<DbPushType<T>>) => Promise<void>;
  deleteDocument: (documentId: string) => Promise<void>;
  fetchDocument: (documentId: string) => void;
  fetchList: (whereClause?: QueryFieldFilterConstraint) => void;
  getDocumentsByKey: (value?: string) => T[];
}

// const globalCache = new Map<string, SnapshotCache>();

// TODO optimize this to only update the document map for the changed document
const updateDocumentMap = <T extends DbType>(get: () => FireStoreState<T>, set: (state: Partial<FireStoreState<T>>) => void) => {
  const { documents, keysToMap } = get();
  const documentMap: { [key: string]: T[] } = {};
  documents.forEach((document) => {
    const documentAsAny = document as any;
    keysToMap.forEach((key) => {
      const keyValue = documentAsAny[key];
      if (keyValue) {
        const existing = documentMap[keyValue];
        if (existing) {
          existing.push(document);
        } else {
          documentMap[keyValue] = [document];
        }
      }
    });
  });
  set({ documentMap });
};

const emptyArr: any = [];

export const createStore = <T extends DbType>(dbName: string, keysToMap: string[]): UseBoundStore<StoreApi<FireStoreState<T>>> => {
  if (storeCache[dbName]) return storeCache[dbName];

  const store = create<FireStoreState<T>>()((set, get) => ({
    dbName: "unset",
    documents: [],
    documentById: {},
    loading: true,
    error: false,
    currentDocument: undefined,
    snapshots: [],
    keysToMap,
    documentMap: {},

    getDocumentsByKey(value?: string) {
      if (!value) return console.error("getDocumentsByKey called with no value", { value });
      const { documentMap } = get();
      if (value) return documentMap[value] ?? emptyArr;
      return emptyArr;
    },

    setDocuments(documents: T[]) {
      const documentById: { [key: string]: T } = {};
      documents.forEach((document) => {
        documentById[document.id] = document;
      });
      set({ documents, documentById });
      updateDocumentMap(get, set);
    },

    fetchDocument(documentId: string) {
      if (!documentId) return; // console.error("fetchDocument called with no documentId", { documentId });
      const { dbName } = get();
      const document = get().documentById[documentId];
      if (!document) {
        const existingCache = get().snapshots.find((cache) => cache.id === documentId);
        if (existingCache) return; // console.log("store already has document snapshot", { documentId });

        const snapshot = getDocumentSnapshot<T>(dbName, documentId, (document, err) => {
          // const { currentDocumentId } = get();
          // if (currentDocumentId === documentId) set({ currentDocument: document });
          if (document) {
            const otherDocuments = get().documents.filter((document) => document.id !== documentId);
            const documents = [ document, ...otherDocuments ];
            //const currentDocument = documentById[(document) => document.id === currentDocumentId);
            get().setDocuments(documents);//, currentDocument });
            //console.log("store got new firestore document", { dbName, document });
          } else {
            const snapshots = get().snapshots.filter((cache) => cache.id !== documentId);
            set({ snapshots });
            console.error("store got firestore document error", { dbName, documentId, err });
          }
        });
        const cache: SnapshotCache = { id: documentId, snapshot };
        set({ snapshots: [ cache, ...get().snapshots] });
      }
    },
    createDocument(data: DbPushType<T>): Promise<string> {
      return new Promise(async (resolve, reject) => {
        const { dbName } = get();
        console.log("createDocument: ", data);
        const collectionRef = collection(db, dbName);
      
        data.createdAt = Timestamp.now();
        // data.createdBy

        const docRef = await addDoc(collectionRef, data).catch((error) => {
          reject(error); // doc(collectionRef, error.message.split('/').at(-1));
          return error;
        });
        //console.log("Created Document with ID: ", dbName, docRef);

        resolve(docRef.id);
      });
    },
    async updateDocument(id: string, data: Partial<DbPushType<T>>) {
      const { dbName } = get();
      
      data.updatedAt = Timestamp.now();
      
      await setDoc(doc(db, dbName, id), data, { merge: true });
      // console.log("Updated Document with ID: ", dbName, doc);
    },
    async deleteDocument(documentId: string) {
      if (!documentId) return console.error("deleteDocument called with no documentId", { documentId });
      const { dbName } = get();
      await deleteDoc(doc(db, dbName, documentId));
      const documents = get().documents.filter((document) => document.id !== documentId);
      // const currentDocument = documentById[(document) => document.id === currentDocumentId);
      get().setDocuments(documents);
    },
    fetchList(whereClause?: QueryFieldFilterConstraint) {
      const cacheId = "/" + (whereClause?.toString() ?? "");
      const existingCache = get().snapshots.find((cache) => cache.id === cacheId);
      // console.log("fetchList", { dbName: get().dbName, cacheId, whereClause, existingCache });

      if (existingCache) {
        // handleDocs(existingCache.docs);
        return; // console.log("store already has snapshot", { dbName: get().dbName });
      }
      const handleDocs = (docs?: T[], err?: any) => {
        // console.log("store got new firestore collection", { dbName, docs, err });
        if (docs) {
          const listSnapshot = get().snapshots.find((snapshot) => snapshot.id === cacheId);
          // Do id list filtering here
          const newIds = docs.map((doc) => doc.id);
          const oldIds = listSnapshot?.documentIds ?? [];
          const idsToRemove = oldIds.filter((id) => !newIds.includes(id));
          if (listSnapshot) listSnapshot.documentIds = newIds;
          if (idsToRemove.length) console.log("idsToRemove", idsToRemove);

          const snapshotsToUnsub = get().snapshots.filter((s) => s.snapshot !== listSnapshot?.snapshot && docs.find((document) => s.id === document.id));
				  snapshotsToUnsub.forEach((s) => s.snapshot());
          // if (snapshotsToUnsub.length > 0) console.log("snapshotsToUnsub", { dbName, snapshotsToUnsub });

          const snapshots = get().snapshots.filter((snapshot) => !snapshotsToUnsub.includes(snapshot));
          docs.forEach((doc) => listSnapshot && snapshots.push({ id: doc.id, snapshot: listSnapshot.snapshot }));
          set({ snapshots });
          // console.log("updating snapshots", { dbName, snapshots });
          // console.log("store got new firestore collection", { dbName, docs, currentDocument });
          const existingDocs = get().documents.filter((document) => !docs.find((doc) => doc.id === document.id));
          const filteredDocs = existingDocs.filter((doc) => !idsToRemove.includes(doc.id));
          const documents = [ ...docs, ...filteredDocs ];
          // const currentDocument = documentById[(document) => document.id === get().currentDocumentId);
          get().setDocuments(documents);
        } else if (err) {
          console.warn("store got error from firestore collection", { dbName, err });
          // set({ loading: false, error: true });
          // Remove cache
          const snapshots = get().snapshots.filter((cache) => cache.id !== cacheId);
          set({ snapshots });
        }
      };
      // console.log("fetching list", { dbName, cacheId, whereClause });
      const snapshot = getCollectionSnapshot<T>(get().dbName, whereClause, handleDocs);
      const cache: SnapshotCache = { id: cacheId, snapshot };
      set({ snapshots: [ cache, ...get().snapshots] });
    },
  }));
  store.setState({ dbName });
  storeCache[dbName] = store;

  return store;
};

export const useLeaguesStore = createStore<LeagueDef>("leagues", []);
export const useEventsStore = createStore<EventDef>("events", ["leagueId"]);
export const useUsersStore = createStore<UserDef>("users", []);
export const useAreasStore = createStore<AreaDef>("areas", ["eventId"]);
export const useRecordsStore = createStore<RecordDef>("records", ["areaId", "eventId"]);
export const useInvitesStore = createStore<InviteDef>("invites", ["leagueId"]);
