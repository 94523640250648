import React, { PropsWithChildren } from 'react';
import styled from "styled-components/macro";
import { useAuthStore } from '../../store/authStore';
import ActionButton from '../basic/ActionButton';
import CenteredLoading from '../basic/CenteredLoading';
import Header from '../basic/Header';
import BballEvalLogo from '../BballEvalLogo';

const SignInDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 10px;
`;

const ColoredLogo = styled(BballEvalLogo)`
  fill: var(--textColor);
  opacity: 0.7;
  width: 150px;
  height: 150px;
`;

function SignIn({ children }: PropsWithChildren) {
  const loading = useAuthStore(state => state.loading);
  const googleSignIn = useAuthStore(state => state.googleSignIn);

  const handleGoogleSignIn = async () => {
    await googleSignIn().catch(console.log);
  };

  if (loading) return <CenteredLoading />;

  return (
    <SignInDiv>
      <Header title="Sign in" />
      <ColoredLogo />
      Welcome to bballeval!<br/>
      {children && <>
        <br/>
        {children}<br/>
      </>}
      <br/>
      <ActionButton title="Login with Google" onClick={handleGoogleSignIn}>Login with Google</ActionButton>
    </SignInDiv>
  );
}

export default SignIn;
