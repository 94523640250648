import { useNavigate, useParams } from 'react-router-dom';
import CenteredLoading from '../basic/CenteredLoading';
import { useAuthStore } from '../../store/authStore';
import { useAreasStore, useEventsStore, useLeaguesStore, useRecordsStore, useUsersStore } from "../../store/fireStore";
import { useEffect } from "react";
import { createWhereClause } from "../../firestore/firebase";
import { useUIStore } from "../../store/uiStore";
import ActionButtonBar from "../basic/ActionButtonBar";
import ActionButton, { AddButton, RemoveButton } from "../basic/ActionButton";
import { ScrollingView } from "../basic/View";
import RecordsTable from "../tables/RecordsTable";
import Breadcrumb from '../tables/Breadcrumb';
import { createRecordsCsv } from '../../types/csvHelper';
import { useAreaForm } from '../forms/AreaForm';
import { useRecordForm } from '../forms/RecordForm';
import PropertiesTable from '../basic/PropertiesTable';
import usePermissions from './PermissionsHook';
import Header from '../basic/Header';
import { timeStampToString } from '../../types/helpers';
import NotFound from '../NotFound';
import EvaluatorAreaView from '../eval/EvaluatorAreaView';
import { useGetRequisites } from '../../firestore/firestoreHooks';

function AreaView() {
  const navigate = useNavigate();
	const { areaId } = useParams<{ areaId: string }>();
  const user = useAuthStore((state) => state.user);
  const userById = useUsersStore((state) => state.documentById);
  const fetchUser = useUsersStore((state) => state.fetchDocument);
  const setPromptProps = useUIStore((state) => state.setPromptProps);
  const area = useAreasStore((state) => state.documentById[areaId ?? ""]);
  const event = useEventsStore((state) => state.documentById[area?.eventId ?? ""]);
  const areaById = useAreasStore((state) => state.documentById);
  const league = useLeaguesStore((state) => state.documentById[area?.leagueId ?? ""]);
  const deleteArea = useAreasStore((state) => state.deleteDocument);
  const records = useRecordsStore((state) => state.getDocumentsByKey(areaId));
  const fetchRecords = useRecordsStore((state) => state.fetchList);
  const { openEditAreaForm } = useAreaForm(league?.id, event?.id);
  const { openNewRecordForm, openEditRecordForm } = useRecordForm(league?.id, event?.id, area);
  const { canEdit, isOwner, canView } = usePermissions(league);
  useGetRequisites(area?.leagueId, area?.eventId, areaId);

  useEffect(() => {
    if (areaId) {
      const where = createWhereClause("areaId", "==", areaId);
      fetchRecords(where);
    }
  }, [areaId, fetchRecords]);

  useEffect(() => {
    if (area) {
      fetchUser(area.createdBy);
    }
  }, [area, fetchUser]);
  
  const createCsv = () => {
    createRecordsCsv(records, areaById);
  };

  const addRecord = () => {
    openNewRecordForm();
  };

  const editArea = () => {
    if (!area) return;

    openEditAreaForm(area);
  };

  const removeArea = async () => {
    if (!user || !area || !areaId) return;
    
    const prompt = {
      title: `Delete ${area.name}`,
      message: `Are you sure you want to delete this area?\nType '${area.name}' to confirm.`,
      onConfirm: async (val: string) => {
        if (val !== area.name) return;

        let eventId = area.eventId;
        await deleteArea(areaId);
        console.log("Deleted Area");
        navigate("/event/" + eventId);
      },
    };
    setPromptProps(prompt);
  };

  const editRecord = (i: number) => {
    openEditRecordForm(records[i]);
  };

  if (!user || !area || !league || !event)
    return (<CenteredLoading />);

  if (!canView)
    return (<NotFound>Access Denied</NotFound>);

  if (!canEdit) return <EvaluatorAreaView />;

  return (
    <ScrollingView>
      <Header title={area.name} windowTitleOverride={event.name + " - " + area.name} />
      <Breadcrumb leagueId={league.id} eventId={event.id} areaId={area.id} />
      <RecordsTable
        area={area}
        records={records}
        onAddClick={addRecord}
        onRowClick={editRecord}
      />

      {canEdit && <PropertiesTable
        title='Area Details'
        properties={[
          { name: "Area Name", value: area.name },
          { name: "Area Type", value: area.type },
          { name: "Area Created By", value: userById[area.createdBy ?? ""]?.name ?? "--" },
          { name: "Area Created At", value: timeStampToString(area.createdAt) },
          { name: "League Name", value: league.name },
          { name: "Event Name", value: event.name },
          { name: "Event Date", value: timeStampToString(event.eventDate) },
          { name: "Records", value: "" + records.length },
        ]}
      />}

      {canEdit && <ActionButtonBar>
        <ActionButton title="Edit Area" onClick={editArea} />
        {isOwner && <RemoveButton title="Delete Area" onClick={removeArea} />}
      </ActionButtonBar>}
      {canEdit && <ActionButtonBar>
        <AddButton title="Export CSV" onClick={createCsv} />
      </ActionButtonBar>}
    </ScrollingView>
  );
}

export default AreaView;
