import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import { useUIStore } from '../../store/uiStore';
import { CanEdit, CanOwn, CanView, LeagueDef, UserRole } from '../../types/firestoreTypes';

interface Permissions {
  myRole: UserRole;
  isOwner: boolean;
  canEdit: boolean;
  canView: boolean;
};

const initialPermissions: Permissions = {
  myRole: UserRole.None,
  isOwner: false,
  canEdit: false,
  canView: false,
};

function usePermissions(league?: LeagueDef) {
  const user = useAuthStore((state) => state.user);
  const location = useLocation();
  const forceEvaluatorView = useUIStore((state) => state.forceEvaluatorView);

  const [myRole, setMyRole] = useState<UserRole>(UserRole.None);
  const [permissions, setPermissions] = useState<Permissions>(initialPermissions);

  useEffect(() => {
    if (!user) return;
    const role = (league && user && league.roles?.[user.uid]) || UserRole.None;
    setMyRole(role);
  }, [user, myRole, league]);

  useEffect(() => {
    if (!user) return;
    const canView = CanView(myRole);
    const forceViewOnly = canView && forceEvaluatorView;
    const isOwner = CanOwn(myRole) && !forceViewOnly;
    const canEdit = CanEdit(myRole) && !forceViewOnly;
    // console.log("usePermissions", { myRole, canView, forceViewOnly, rawCanOwn, rawCanEdit, canOwn, canEdit });
    
    const permissions = {
      myRole,
      isOwner,
      canEdit,
      canView,
    };
    setPermissions(permissions);
  }, [user, myRole, location, forceEvaluatorView]);


  return permissions;
}

export default usePermissions;
