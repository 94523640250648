import React, { useEffect, useState } from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import ReactTable, { ReactTableCallback } from './ReactTable';
import { AreaDef, AreaType, RecordDef } from '../../types/firestoreTypes';
import { useUsersStore } from '../../store/fireStore';
import { renderScore } from './RecordsTable';

interface PlayerRecordRow {
	areaName: string;
	areaType: AreaType;
	score: string;
	evaluator: string;
}
const columnHelper = createColumnHelper<PlayerRecordRow>()

export interface AreaRecord {
	area: AreaDef;
	record?: RecordDef;
}

interface Props extends ReactTableCallback {
	records: AreaRecord[];
}

export default function PlayerRecordsTable({ records, onRowClick }: Props) {
	const [data, setData] = useState<PlayerRecordRow[]>([]);
	const userById = useUsersStore(state => state.documentById);
	const fetchUser = useUsersStore(state => state.fetchDocument);
	
	const [columns, setColumns] = useState<ColumnDef<PlayerRecordRow, string>[]>([]);

	useEffect(() => {
		const cols = [
			columnHelper.accessor('areaName', {
				header: () => 'Area',
			}),
			columnHelper.accessor('score', {
				header: () => 'Score',
				cell: ({ renderValue, row }) => renderScore(renderValue(), row.original.areaType),
			}),
			columnHelper.accessor('evaluator', {
				header: () => 'Evaluator',
			}),
		];
		setColumns(cols);
	}, []);

	useEffect(() => {
		if (!records) return;
		const recordRows: PlayerRecordRow[] = [];
		records.forEach(({ record, area }) => {
			if (record) fetchUser(record.evaluatorId);
			recordRows.push({
				areaName: area.name,
				areaType: area.type,
				score: record?.rating ?? "",
				evaluator: userById[record?.evaluatorId ?? ""]?.name ?? record?.evaluatorId,
			});
		});
		setData(recordRows);
	}, [fetchUser, records, userById]);

	return <ReactTable
			columns={columns}
			data={data}
			noResultsLabel="No Records Found"
			onRowClick={onRowClick}
		/>
}
