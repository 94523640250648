import { useEffect, useState } from "react"
import styled from "styled-components/macro";

export const StyledInput = styled.input`
  font-size: 1.2rem;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  outline-offset: -1px;
  min-width: 100px;
  padding: 0px 10px;
  transition: border-color 0.2s;
  flex-shrink: 1;
  :hover {
    border-color: #4f74be;
  }
`;

interface Props {
  value: string | number;
  onChange: (value: string | number) => void;
}

// A input react component
export default function FormInput({
  value: initialValue,
  onChange,
  ...props
}: Props & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState<string | number>("");

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  const handleValueChanged = (newValue: string | number) => {
    if (value !== newValue) {
      onChange(newValue)
      setValue(newValue);
    }
  };

  return (
    <StyledInput {...props} value={value ?? ""} onChange={e => handleValueChanged(e.target.value)} />
  )
}