import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro";

const DurationDiv = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
  overflow: hidden;
  user-select: none;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: auto;
  position: relative;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DurationSlider = styled.div`
  height: 100%;
  background-color: #6666;
  margin-left: 50%;
  margin-right: 50%;
  position: relative;
`;
const DurationSpacer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`;

const CenterMarker = styled.div`
  width: 3px;
  height: 100%;
  background-color: var(--buttonRemove);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const Value = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--button);
  color: var(--buttonText);
  font-size: 16px;
  padding: 2px 5px;
  border-radius: 5px;
  pointer-events: none;
`;

const Tick = styled.div`
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 20px;
  background-color: var(--textColor);
  pointer-events: none;

  &.small {
    width: 1px;
    height: 10px;
  }
`;

const TickLabel = styled.div`
  position: absolute;
  bottom: 25px;
  transform: translateX(-50%);
  color: var(--textColor);
  font-size: 14px;
  pointer-events: none;
`;

const IncrementButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--button);
  color: var(--buttonText);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
  line-height: 35px;
  cursor: pointer;
`;

interface Props {
  range?: number[];
  value?: number;
  onChange: (value: number) => void;
}

const pixelsPerSecond = 100;
let dragOffset = 0;


// A input react component
export default function DurationInput({
  range,
  value: initial,
  onChange,
}: Props) {
  const onChangeRef = useRef(onChange);
  // const val = useRef(initial ?? range?.[0] ?? 0);
  const [value, setValue] = useState(initial ?? range?.[0] ?? 0);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  //val.current = value;

  // Add mouse drag support
  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;
    const onMouseDown = (e: MouseEvent) => {
      if (!(slider.firstChild instanceof HTMLElement)) return;
      dragOffset = e.pageX + slider.scrollLeft;

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    };
    const onMouseMove = (e: MouseEvent) => {
      slider.scrollLeft = dragOffset - e.clientX;
    };
    const onMouseUp = () => {
      dragOffset = 0;
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
    slider.addEventListener("mousedown", onMouseDown);
    return () => {
      slider.removeEventListener("mousedown", onMouseDown);
    };
  }, [sliderRef]);

  useEffect(() => {
    // Bind to the slider so we know if the scroll position changes
    const slider = sliderRef.current;
    if (!slider) return;
    const onScroll = () => {
      if (!range) return;
      const scrollLeft = slider.scrollLeft;
      const newValue = Math.max(range[0], Math.min(range[1], scrollLeft / pixelsPerSecond + range[0]));
      setValue(newValue);
      onChangeRef.current(parseFloat(newValue.toFixed(2)));
    };
    slider.addEventListener("scroll", onScroll);
    return () => {
      slider.removeEventListener("scroll", onScroll);
    };
  }, [onChangeRef, range, sliderRef]);

  const incrementValue = (amount: number) => {
    if (!range) return;
    const newValue = Math.max(range[0], Math.min(range[1], value + amount));
    if (sliderRef.current) sliderRef.current.scrollLeft = (newValue - range[0]) * pixelsPerSecond;
    setValue(newValue);
    onChange(parseFloat(newValue.toFixed(2)));
  };

  if (!range) return null;

  const duration = range[1] - range[0];
  const ticksPerSecond = 4;
  const ticks = duration * ticksPerSecond;
  const tickPositions = new Array(ticks).fill(0).map((_, i) => i / ticksPerSecond * pixelsPerSecond);
  tickPositions.push(duration * pixelsPerSecond);

  return (
	<DurationDiv>
    <SliderContainer ref={sliderRef}>
      <DurationSlider
        style={{ width: duration * pixelsPerSecond }}
      >
        {tickPositions.map((x, i) => (
          <Tick className={i % ticksPerSecond === 0 ? "" : "small"} key={i} style={{ left: x }} />
        ))}
        {tickPositions.map((x, i) => {
          if (i % ticksPerSecond !== 0) return null;
          const seconds = i / ticksPerSecond + range[0];

          return (
            <TickLabel key={i} style={{ left: x + 0.5 }} >
              {seconds.toFixed(0)}
            </TickLabel>
          );
        })}
      </DurationSlider>
      <DurationSpacer style={{ left: duration * pixelsPerSecond }} />
    </SliderContainer>
    <CenterMarker />
    <Value>{value.toFixed(2)}</Value>
    <IncrementButton style={{ left: 0 }} onClick={() => incrementValue(0.01)}>-</IncrementButton>
    <IncrementButton style={{ right: 0 }} onClick={() => incrementValue(-0.01)}>+</IncrementButton>
	</DurationDiv>
  )
}