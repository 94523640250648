import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, enableMultiTabIndexedDbPersistence, FieldPath, FirestoreError, getFirestore, onSnapshot, Query, query, QueryFieldFilterConstraint, where, WhereFilterOp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getFunctions } from "@firebase/functions";
import { localStorageGet, localStorageSet } from "../store/localStorage";
import { useUIStore } from "../store/uiStore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRESTORE_APP_ID,
  measurementId: process.env.REACT_APP_FIRESTORE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const db = getFirestore(app);
//connectFirestoreEmulator(db, 'localhost', 8080);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
//provider.setCustomParameters({ prompt: 'select_account' });
export const storage = getStorage();
export const functions = getFunctions();

// initializeFirestore(app, {}, userId)// Use multi-tab IndexedDb persistence.
// Enable localdb persistence
enableMultiTabIndexedDbPersistence(db)
  .catch((err) => console.error("enableMultiTabIndexedDbPersistence", err));

const PENDING_WRITES_KEY = "pendingWrites";
const pendingWrites: { [key: string]: boolean } = JSON.parse(localStorageGet(PENDING_WRITES_KEY) ?? "{}");

const excludeDbs = ["users"];

const updatePendingWrites = (id: string, isPending: boolean) => {
  const existing = pendingWrites[id] ?? false;
  // console.log("updatePendingWrites", { id, isPending, existing, pendingWrites });
  if (isPending !== existing) {
    if (isPending) pendingWrites[id] = true;
    else delete pendingWrites[id];
  }
  else return;
  
  localStorageSet(PENDING_WRITES_KEY, JSON.stringify(pendingWrites));
  useUIStore.getState().setPendingWrites(Object.keys(pendingWrites).length);
};


// Helpers
export function getCollectionSnapshot<T>(dbName: string, whereClause: QueryFieldFilterConstraint | undefined, callback: (collectionData?: T[], err?: FirestoreError) => void) {
	const documentCollection = collection(db, dbName);
	const getQuery = (whereClause ? query(documentCollection, whereClause) : query(documentCollection)) as Query<T>;
	const options = { includeMetadataChanges: true };
	return onSnapshot(getQuery, options, (querySnapshot) => {
		const collectionArr = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
		if (!excludeDbs.includes(dbName))
			querySnapshot.docs.forEach((doc) => updatePendingWrites(doc.id, doc.metadata.hasPendingWrites));
		// console.log("firestore collection got new data", { dbName, collectionArr });
		callback(collectionArr, undefined);
	},
	(err) => {
		// console.warn("Firestore error", { dbName, whereClause, err });
		callback(undefined, err);
	});
}

export function getDocumentSnapshot<T>(dbName: string, documentId: string, callback: (documentData?: T, err?: FirestoreError) => void) {
	const getQuery = doc(db, dbName, documentId);
	const options = { includeMetadataChanges: true };
	return onSnapshot(getQuery, options, (document) => {
		if (!excludeDbs.includes(dbName))
			updatePendingWrites(document.id, document.metadata.hasPendingWrites);

		const data = document.data();
		if (!data) {
			// console.warn("Firestore document data is null", { dbName, documentId });
			// callback(undefined, undefined);
			return;
		}
		const documentData = { id: documentId, ...document.data()} as T;
		// console.log("firestore document got new data", { dbName, documentId, documentData });
		callback(documentData, undefined);
	}, (err) => {
		console.warn(err);
		callback(undefined, err);
	});
}

export function createWhereClause(field: string | FieldPath, operator: WhereFilterOp, value: string | string[]) {
	const w = where(field, operator, value);
	(w as any).tostring = `${field} ${operator} ${value}`;
	w.toString = () => (w as any).tostring;
	return w;
}

export function uploadFile(file: File, name: string, callback: (url: string) => void) {
	const storageRef = ref(storage, name);
	uploadBytes(storageRef, file).then((snapshot) => {
		console.log('Uploaded a blob or file!');
		callback(snapshot.metadata.fullPath);
	});
}

export function getFileUrl(name: string, callback: (url: string) => void) {
	const storageRef = ref(storage, name);
	getDownloadURL(storageRef).then((downloadURL) => {
		console.log('File available at', downloadURL);
		callback(downloadURL);
	});
}
