import { useEffect, useState } from "react"
import styled from "styled-components/macro";

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
`;

const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 1.2rem;
  text-align: left;
  border: 1px solid #8888;
  padding: 5px;
  border-radius: 5px;
`;

export const Checkbox = styled.input`
  appearance: none;
  width: ${(props: { size?: number }) => props.size ?? 20}px;
  height: ${(props: { size?: number }) => props.size ?? 20}px;
  border: 1px solid var(--textColor);
  background-color: transparent;
  display: grid;
  place-content: center;
  border-radius: 5px;
  :before {
    content: "";
    width: ${(props: { size?: number }) => props.size ?? 20}px;
    height: ${(props: { size?: number }) => props.size ?? 20}px;
    border-radius: 5px;
    background-color: var(--textColor);
    transform: scale(0);
    transition: transform 0.1s;
  }
  :checked {
    :before {
      transform: scale(1);
    }
  }
`;

interface Props {
  options?: string[];
  values?: boolean[];
  onChange: (values: boolean[]) => void;
}

// A input react component
export default function CheckboxesInput({
  options,
  values: initial,
  onChange,
}: Props) {
  const [values, setValues] = useState<boolean[]>([]);

  useEffect(() => {
    if (options) {
      const newValues = options.map((_, i) => initial?.[i] ?? false);
      // console.log(newValues);
      setValues(newValues);
    }
  }, [initial, options]);

  const handleValueChanged = (i: number, newValue: boolean) => {
	  const existing = values[i];
    if (existing !== newValue) {
      const newValues = [...values];
      newValues[i] = newValue;
      onChange(newValues)
      setValues(newValues);
    }
  }

  return (
	<CheckboxesContainer>
		{options?.map((option, i) => {
			return (
				<CheckboxRow key={i} onClick={() => handleValueChanged(i, !values[i])}>
          <Checkbox type="checkbox" checked={values[i] ?? false} onChange={e => handleValueChanged(i, e.target.checked)} />
					{option}
				</CheckboxRow>
			)
		})}
	</CheckboxesContainer>
  )
}